import React from "react";
import { TbHandClick } from "react-icons/tb";

function Statute() {
  return (
    <section className="">
      <div className="container mx-auto">
        <div className="flex flex-col mb-24 gap-12">
          <h2 className="text-2xl sm:text-3xl md:text-4xl font-extrabold">
            Organy Stowarzyszenia
          </h2>
          <div className="flex justify-around gap-4 flex-col lg:flex-row">
            <div className="flex flex-col gap-6 p-6 shadow-lg rounded-lg">
              <h3 className="text-xl sm:text-2xl font-bold">Zarząd:</h3>
              <p className="text-sm leading-relaxed md:text-lg md:leading-relaxed">
                <strong>Partycja Dobrowolska</strong> — Prezes i Skarbnik
                <br /> <strong>Katarzyna Kalużna</strong> — Wiceprezes
                <br /> <strong>Joanna Bartecka</strong> — Sekretarz
              </p>
            </div>
            <div className="flex flex-col gap-6 p-6 shadow-lg rounded-lg">
              <h3 className="text-xl sm:text-2xl font-bold">
                Komisja Rewizyjna:
              </h3>
              <p className="text-sm leading-relaxed md:text-lg md:leading-relaxed">
                <strong>Arkadiusz Mendel</strong> — Przewodniczący Komisji
                <br /> <strong>Żaneta Żmuda Kozina</strong> — Z-ca
                Przewodniczącego
                <br /> <strong>Joanna Wołoszka</strong> — Członek Komisji
              </p>
            </div>
          </div>
        </div>
        <div tabIndex="0" className="collapse group rounded-lg mb-24">
          <div className="collapse-title bg-primary text-primary-content group-focus:bg-secondary group-focus:text-secondary-content">
            <div className="flex items-center justify-center gap-2">
              <div className="text-sm leading-relaxed md:text-lg md:leading-relaxed">
                Statut Stowarzyszenia —{" "}
                <strong>POMIECHOWSKIE BEZDOMNIAKI</strong>
              </div>
              <div>
                <TbHandClick style={{ height: "24px", width: "24px" }} />
              </div>
            </div>
          </div>
          <div className="collapse-content bg-primary text-primary-content group-focus:bg-secondary group-focus:text-secondary-content">
            {/* Rozdział 1 - Start */}
            <div className="flex flex-col gap-4 mb-12">
              <div className="text-xl self-center">
                Rozdział I — Postanowienia Ogólne
              </div>
              <div className="text-xl self-center">§ 1</div>
              <ol className="list-decimal px-8">
                <li>
                  Stowarzyszenie nosi nazwę Stowarzyszenie „Pomiechowskie
                  Bezdomniaki”, zwane dalej Stowarzyszeniem.
                </li>
                <li>Siedzibą Stowarzyszenia jest Pomiechówek.</li>
              </ol>
              <div className="text-xl self-center">§ 2</div>
              <ol className="list-decimal px-8">
                <li>
                  Stowarzyszenie ma osobowość prawną i działa na podstawie
                  przepisów ustawy z dnia 7 kwietnia 1989 r. Prawo o
                  stowarzyszeniach (Dz. U. z 2001r Nr 79, poz.855 ze zm.) oraz
                  niniejszego Statutu. Powołane jest na czas nieokreślony.
                </li>
              </ol>
              <div className="text-xl self-center">§ 3</div>
              <ol className="list-decimal px-8">
                <li>
                  Terenem działania Stowarzyszenia jest obszar Rzeczypospolitej
                  Polskiej ze szczególnym uwzględnieniem gminy Pomiechówek.
                </li>
                <li>
                  Stowarzyszenie może być członkiem innych stowarzyszeń o tym
                  samym, lub podobnym profilu działania.
                </li>
                <li>
                  Działalność Stowarzyszenia opiera się na pracy społecznej jego
                  członków w ramach wolontariatu.
                </li>
                <li>
                  Stowarzyszenie może zatrudniać pracowników, lub zlecać zadania
                  innym podmiotom.
                </li>
                <li>
                  Stowarzyszenie może prowadzić nieodpłatną i odpłatą
                  działalność pożytku publicznego, przy czym cały dochód z
                  działalności odpłatnej jest przeznaczony wyłącznie na cele
                  statutowe.
                </li>
              </ol>
            </div>
            {/* ROZDZIAL 2 - START */}

            <div className="flex flex-col gap-4 mb-12">
              <div className="text-xl self-center">
                Rozdział II — Cele Stowarzyszenia i sposoby działania
              </div>
              <div className="text-xl self-center">§ 4</div>
              <p>Celami stowarzyszenia są:</p>
              <ol className="list-decimal px-8">
                <li>Podejmowanie działań mających na celu pomoc zwierzętom.</li>
                <li>
                  Niesienie pomocy zwierzętom zagubionym i bezdomnym, w
                  szczególności pochodzącym i znajdującym się na terenie gminy
                  Pomiechówek.
                </li>
                <li>
                  Działanie na rzecz humanitarnego i prawidłowego traktowania
                  zwierząt, poszanowania ich praw, oraz zapewniania im
                  prawidłowych warunków bytowania.
                </li>
                <li>
                  Przeciwdziałanie okrucieństwu wobec zwierząt, działanie w ich
                  obronie i niesienie im pomocy.
                </li>
                <li>
                  Prowadzenie szkoleń w zakresie prawidłowego i umiejętnego
                  postępowania ze zwierzętami.
                </li>
                <li>
                  Kształtowanie wśród społeczeństwa umiłowania i ochrony
                  środowiska naturalnego, a zwłaszcza świata zwierząt.
                </li>
                <li>
                  Podejmowanie działań zmierzających do zwalczania bezdomności
                  zwierząt
                </li>
                <li>
                  Aktywizacja osób niepełnosprawnych poprzez kontakt ze
                  zwierzętami.
                </li>
              </ol>
              <div className="text-xl self-center">§ 5</div>
              <p>Formy realizacji celów stowarzyszenia:</p>
              <ol className="list-decimal px-8">
                <li>
                  Stowarzyszenie jest organizacją o charakterze niezarobkowym -
                  non profit.
                </li>
                <li>
                  Stowarzyszenie może wyrażać swoje stanowisko na forum
                  publicznym.
                </li>
                <li>
                  Stowarzyszenie może podejmować współpracę z organami
                  administracji publicznej wspierając działania opieki nad
                  zwierzętami, oraz kierować postulaty mające na celu poprawę
                  ich losu.
                </li>
                <li>
                  Stowarzyszenie może organizować szkolenia, konferencje i
                  pokazy.
                </li>
                <li>
                  Stowarzyszenie może współpracować ze schroniskami dla
                  zwierząt, podejmować działania na rzecz poprawy bytowania
                  zwierząt przebywających w schroniskach we współpracy z
                  właścicielami oraz zarządcami tych placówek.
                </li>
                <li>
                  Stowarzyszenie może podejmować wyłapywanie, leczenie i
                  rehabilitację zwierząt bezdomnych.
                </li>
                <li>
                  Stowarzyszenie dąży do popularyzowania czipowania zwierząt
                  jako formy zapobiegania ich bezdomności.
                </li>
                <li>
                  Stowarzyszenie prowadzi działania dążące do zmniejszania
                  populacji bezdomnych zwierząt.
                </li>
                <li>
                  Stowarzyszenie podejmuje działania zmierzające do adopcji
                  zwierząt bezdomnych.
                </li>
                <li>
                  Stowarzyszenie może podjąć działania pomagające osobom
                  prywatnym, które mają trudności w sprawowaniu prawidłowej
                  opieki nad zwierzętami.
                </li>
                <li>
                  Stowarzyszenie wspiera wszelkie inne działania mające na celu
                  pomoc zwierzętom bezdomnym.
                </li>
                <li>
                  Stowarzyszenie może współdziałać z właściwymi organami
                  publicznymi w zakresie ścigania winnych okrutnego obchodzenia
                  się ze zwierzętami, wspierać działalność edukacyjną prowadzoną
                  na rzecz ochrony zwierząt, oraz kształtować świadomość
                  ekologiczną społeczeństwa.
                </li>
                <li>
                  Stowarzyszenie promuje organizację wolontariatu na rzecz
                  bezdomnych zwierząt
                </li>
                <li>
                  Stowarzyszenie może prowadzić akcje edukacyjne i promocyjne za
                  pośrednictwem środków masowego przekazu, oraz wydawnictw
                  własnych.
                </li>
              </ol>
            </div>

            {/* ROZDZIAL 3 START */}
            <div className="flex flex-col gap-4 mb-12">
              <div className="text-xl self-center">
                Rozdział III — Członkowie, ich prawa i obowiązki
              </div>
              <div className="text-xl self-center">§ 6</div>
              <p>Członkowie Stowarzyszenia dzielą się na:</p>
              <ul className="list-disc px-8">
                <li>Zwyczajnych</li>
                <li>Wspierających</li>
                <li>Honorowych</li>
              </ul>

              <div className="text-xl self-center">§ 7</div>
              <ol className="list-decimal px-8">
                <li>
                  Członkiem zwyczajnym może być osoba fizyczna, która złoży
                  pisemną deklarację chęci przynależności do Stowarzyszenia.
                </li>
                <li>
                  Przyjęcia nowych członków dokonuje Zarząd uchwałą podjętą nie
                  później niż w ciągu miesiąca od daty złożenia wniosku.
                </li>
                <li>
                  Od uchwały Zarządu odmawiającej przyjęcia kandydata w poczet
                  członków Stowarzyszenia przysługuje prawo odwołania do Walnego
                  Zebrania Członków Stowarzyszenia w terminie 14 dni od dnia
                  doręczenia uchwały.
                </li>
              </ol>
              <div className="text-xl self-center">§ 8</div>
              <p>Członek zwyczajny ma prawo:</p>
              <ol className="list-decimal px-8">
                <li>Czynnego i biernego wyboru władz Stowarzyszenia.</li>
                <li>Uczestnictwa w Walnym Zebraniu Członków.</li>
                <li>
                  Korzystania z urządzeń i pomocy organizacyjnej Stowarzyszenia.
                </li>
                <li>
                  Zgłaszania postulatów i wniosków dotyczących działalności
                  Stowarzyszenia.
                </li>
                <li>
                  Reprezentowania i występowania na zewnątrz Stowarzyszenia w
                  ramach pełnomocnictw udzielonych przez Zarząd Stowarzyszenia.
                </li>
              </ol>
              <div className="text-xl self-center">§ 9</div>
              <p>Członek zwyczajny zobowiązany jest do:</p>
              <ol className="list-decimal px-8">
                <li>
                  Przestrzegania postanowień Statutu i uchwał Zarządu
                  Stowarzyszenia.
                </li>
                <li>
                  Aktywnego uczestnictwa w realizacji celów Stowarzyszenia.
                </li>
                <li>
                  Regularnego opłacania składki członkowskiej. Z obowiązku
                  opłacania składki członkowskiej są zwolnione osoby
                  niepełnoletnie.
                </li>
              </ol>
              <div className="text-xl self-center">§ 10</div>
              <ol className="list-decimal px-8">
                <li>
                  Członkiem wspierającym może być osoba prawna, zainteresowana
                  merytoryczną działalnością Stowarzyszenia, która zadeklarowała
                  składkę członkowską na rzecz Stowarzyszenia i zostanie
                  przyjęta na podstawie pisemnej deklaracji przez Zarząd
                  Stowarzyszenia.
                </li>
                <li>
                  Członek wspierający posiada wszystkie prawa członka
                  zwyczajnego z wyjątkiem czynnego i biernego prawa wyborczego.
                </li>
                <li>
                  Członek wspierający może działać w Stowarzyszeniu za
                  pośrednictwem swojego przedstawiciela.
                </li>
                <li>
                  Od uchwały Zarządu odmawiającej przyjęcia kandydata w poczet
                  członków Stowarzyszenia przysługuje mu prawo odwołania do
                  Walnego Zebrania Członków Stowarzyszenia w terminie 14 dni od
                  dnia doręczenia uchwały.
                </li>
              </ol>
              <div className="text-xl self-center">§ 11</div>
              <ol className="list-decimal px-8">
                <li>
                  Członkostwo honorowe nadaje Zarząd Stowarzyszenia osobom
                  szczególnie zasłużonym dla Stowarzyszenia lub idei ochrony
                  zwierząt.
                </li>
                <li>
                  Członek honorowy posiada wszystkie prawa członka zwyczajnego,
                  w tym również przysługuje mu czynne i bierne prawo wyboru do
                  władz Stowarzyszenia.
                </li>
                <li>
                  Członek honorowy jest zwolniony z obowiązku opłacania składek
                  członkowskich.
                </li>
              </ol>
              <div className="text-xl self-center">§ 12</div>
              <p>Członkostwo zwyczajne w Stowarzyszeniu ustępuje w razie:</p>
              <ol className="list-decimal px-8">
                <li>Pisemnej rezygnacji z członkostwa.</li>
                <li>
                  Wykluczenia z powodu nieusprawiedliwionego zalegania z opłatą
                  składek członkowskich przez okres 6 miesięcy od terminu
                  statutowego, rażącego naruszenia postanowień Statutu,
                  regulaminu, bądź uchwał Stowarzyszenia albo prowadzenia
                  działalności sprzecznej z celami Stowarzyszenia.
                </li>
                <li>
                  Skazania prawomocnym wyrokiem sądu za przestępstwo ścigane z
                  oskarżenia publicznego, albo przestępstwo skarbowe, śmierci
                  członka, utraty przez niego pełnej zdolności do czynności
                  prawnych, albo utraty praw publicznych.
                </li>
              </ol>
              <div className="text-xl self-center">§ 13</div>
              <p>Członkostwo wspierajce w Stowarzyszeniu ustępuje w razie:</p>
              <ol className="list-decimal px-8">
                <li>Pisemnej rezygnacji z członkostwa.</li>
                <li>Przeprowadzenia likwidacji lub ogłoszenia upadłości.</li>
                <li>
                  Wykluczenia z powodu nieusprawiedliwionego zalegania z opłatą
                  składek członkowskich przez okres przekraczający 6 miesięcy,
                  rażącego naruszenia postanowień Statutu, regulaminów uchwał
                  Stowarzyszenia albo prowadzenia działalności sprzecznej z
                  celami Stowarzyszenia.
                </li>
              </ol>
              <div className="text-xl self-center">§ 14</div>
              <p>Członkostwo honorowe w Stowarzyszeniu ustępuje w razie:</p>
              <ol className="list-decimal px-8">
                <li>Zrzeczenia się tej godności.</li>
                <li>
                  Pozbawienia tej godności uchwałą Zarządu Stowarzyszenia.
                </li>
              </ol>
              <div className="text-xl self-center">§ 15</div>
              <p>
                Decyzję w sprawie wykluczenia członka albo pozbawienia godności
                członka honorowego podejmuje Zarząd Stowarzyszenia w drodze
                uchwały. Od uchwały Zarządu Stowarzyszenia członkom przysługuje
                prawo odwołania się do Walnego Zebrania Członków Stowarzyszenia
                w terminie 14 dni od dnia powzięcia informacji o uchwale, nie
                później jednak niż w terminie 1 roku od dnia podjęcia uchwały.
              </p>
            </div>

            {/* ROZDZIAL 4 - START */}
            <div className="flex flex-col gap-4 mb-12">
              <div className="text-xl self-center">
                Rozdział IV — Organy Stowarzyszenia
              </div>
              <div className="text-xl self-center">§ 16</div>
              <p>Organami Stowarzyszenia są:</p>
              <ol className="list-decimal px-8">
                <li>Walne Zebranie Członków Stowarzyszenia</li>
                <li>Zarząd Stowarzyszenia</li>
                <li>Komisja Rewizyjna Stowarzyszenia</li>
              </ol>
              <div className="text-xl self-center">§ 17</div>
              <ol className="list-decimal px-8">
                <li>
                  Zarząd i Komisja Rewizyjna powoływane są i odwoływane przez
                  Walne Zebranie Członków a ich wybór odbywa się w głosowaniu
                  jawnym bezwzględną większością głosów. Na żądanie choćby
                  jednego z członków Stowarzyszenia, wybór ten odbywa się w
                  głosowaniu tajnym.
                </li>
                <li>
                  Kandydat musi wyrazić zgodę na objęcie kandydatury pod
                  głosowanie.
                </li>
                <li>
                  Kandydaci, którzy otrzymają największą ilość głosów pełnią
                  odpowiednio funkcję Prezesa Zarządu oraz Przewodniczącego
                  Komisji Rewizyjnej.
                </li>
                <li>
                  Kadencja Zarządu wynosi 4 lata od daty podjęcia uchwały o
                  powołaniu.
                </li>
                <li>
                  Kadencja Komisji Rewizyjnej wynosi 4 lata od daty podjęcia
                  uchwały o powołaniu.
                </li>
                <li>
                  Walne Zebranie Członków może odwołać w każdym czasie Zarząd
                  albo Komisję Rewizyjną w całym składzie, jak również
                  poszczególnych członków tych organów.
                </li>
                <li>
                  W przypadku ustania członkostwa władz Stowarzyszenia w trakcie
                  kadencji skład osobowy władz jest uzupełniany poprzez
                  przeprowadzenie wyborów uzupełniających na nieobsadzone
                  stanowisko.
                </li>
              </ol>
              <div className="text-xl self-center">§ 18</div>
              <p>Walne Zebranie Członków</p>
              <ol className="list-decimal px-8">
                <li>
                  Walne Zebranie Członków jest najwyższą władzą Stowarzyszenia.
                </li>
                <li>W Walnym Zebraniu Członków biorą udział:</li>
                <ul className="list-disc px-8">
                  <li>z głosem stanowiącym członkowie zwyczajni</li>
                  <li>
                    z głosem doradczym członkowie wspierający, honorowi i goście
                    zaproszeni przez Zarząd.
                  </li>
                </ul>
              </ol>
              <div className="text-xl self-center">§ 19</div>
              <p>Organami Stowarzyszenia są:</p>
              <ol className="list-decimal px-8">
                <li>
                  Walne Zebranie Członków może być zwyczajne albo nadzwyczajne.
                </li>
                <li>
                  Zwyczajne Walne Zebranie Członków zwołuje Zarząd raz w roku, w
                  terminie do 30 listopada, jako zebranie sprawozdawcze, oraz co
                  4 lata jako zebranie sprawozdawczo-wyborcze.
                </li>
                <li>
                  Obradami Walnego Zebrania Członków kieruje Przewodniczący.
                </li>
                <li>
                  Przewodniczący Walnego Zebrania wybierany jest w głosowaniu
                  jawnym zwykłą większością obecnych członków spośród członków
                  Stowarzyszenia.
                </li>
                <li>
                  Nadzwyczajne Walne Zebranie Członków zwoływane jest w
                  uzasadnionych przypadkach uchwałą Zarządu podjętą z własnej
                  inicjatywy, na wniosek Komisji Rewizyjnej lub na pisemny
                  wniosek co najmniej 2/3 ilości członków zwyczajnych
                  Stowarzyszenia, w terminie nie późniejszym niż 2 miesiące od
                  złożenia wniosku.
                </li>
                <li>
                  O miejscu, terminie i porządku obrad Walnego Zebrania Członków
                  Zarząd powiadamia Członków co najmniej na 14 dni przed
                  terminem zebrania listem poleconym, lub pocztą elektroniczną
                  na wskazany przez członka adres.
                </li>
                <li>
                  Uchwały Walnego Zebrania Członków zapadają zwykłą większością
                  głosów przy obecności co najmniej połowy osób uprawnionych do
                  uczestnictwa w Zebraniu z głosem decydującym.
                </li>
                <li>
                  Członkowie mogą uczestniczyć i głosować podczas Walnego
                  Zebrania Członków przez pełnomocnika. Pełnomocnictwo może być
                  złożone na piśmie i przedłożone Zarządowi co najmniej 3 dni
                  przez terminem Zebrania. Pełnomocnikiem może być inny członek
                  Stowarzyszenia albo inna osoba fizyczna posiadająca pełną
                  zdolność do czynności prawnych.
                </li>
                <li>
                  Jeśli w pierwszym terminie na Walnym Zgromadzeniu Członków nie
                  uczestniczy co najmniej połowa osób uprawnionych do
                  uczestnictwa w Zebraniu z głosem decydującym, wówczas w drugim
                  terminie uchwały mogą być podejmowane większością głosów osób
                  uprawnionych obecnych na drugim Zebraniu. Drugi termin
                  Zebrania powinien być podany w zawiadomieniu o posiedzeniu i
                  nie może być wyznaczony wcześniej niż na 30 minut po upływie
                  pierwszego terminu Zebrania.
                </li>
              </ol>
              <div className="text-xl self-center">§ 20</div>
              <p>
                Do kompetencji zwyczajnego Walnego Zebrania w szczególności
                należy:
              </p>
              <ol className="list-decimal px-8">
                <li>Uchwalenie Statutu i jego zmiana</li>
                <li>Wybór i odwoływanie członków władz Stowarzyszenia.</li>
                <li>
                  Rozpatrywanie i zatwierdzanie sprawozdań Zarządu i udzielanie
                  absolutorium Zarządowi na wniosek Komisji Rewizyjnej.
                </li>
                <li>
                  Rozpatrywanie odwołań od uchwał Zarządu w sprawie przyjmowania
                  nowych członków, lub wykluczenia członka.
                </li>
                <li>
                  Podejmowanie uchwały o rozwiązaniu Stowarzyszenia i
                  przeznaczeniu majątku.
                </li>
                <li>
                  Określenie głównych kierunków działania i rozwoju
                  Stowarzyszenia.
                </li>
                <li>
                  Podejmowanie uchwał w sprawie zaciągania zobowiązań
                  finansowych przez Stowarzyszenie.
                </li>
                <li>
                  Podejmowanie uchwały w sprawie rozpoczęcia bądź zakończenia
                  prowadzenia działalności gospodarczej.
                </li>
                <li>
                  Rozpatrywanie i zatwierdzanie sprawozdań Komisji Rewizyjnej.
                </li>
              </ol>
              <div className="text-xl self-center">§ 21</div>
              <p>Zarząd Stowarzyszenia</p>
              <ol className="list-decimal px-8">
                <li>
                  Zarząd kieruje całokształtem działalności Stowarzyszenia,
                  zgodnie z uchwałami Walnego Zebrania Członków, reprezentuje je
                  na zewnątrz i ponosi odpowiedzialność przed Walnym Zebraniem
                  Członków.
                </li>
                <li>
                  Zarząd składa się z dwóch do pięciu członków pełniących
                  funkcje: Prezesa, Wiceprezesa, Skarbnika i Sekretarza.
                </li>
                <li>
                  O podziale funkcji Wiceprezesa, Skarbnika i Sekretarza
                  decydują członkowie Zarządu między sobą. Prezes i Wiceprezes
                  mogą pełnić jednocześnie funkcję Skarbnika lub Sekretarza.
                </li>
                <li>
                  Uchwały Zarządu zapadają zwykłą większością głosów w
                  głosowaniu jawnym, w obecności przynajmniej połowy liczby
                  członków. W przypadku równej liczby głosów decyduje głos
                  Prezesa.
                </li>
                <li>
                  Do składania oświadczeń woli oraz zaciągania zobowiązań
                  majątkowych w imieniu Stowarzyszenia uprawnieni są łącznie
                  dwaj członkowie Zarządu. Oświadczenia składane wobec
                  Stowarzyszenia oraz doręczenia pism mogą być dokonywane wobec
                  jednego członka Zarządu.
                </li>
                <li>
                  Posiedzenia Zarządu odbywają się nie rzadziej niż 2 razy w
                  roku, na wniosek Prezesa lub dwóch członków Zarządu.
                </li>
                <li>
                  W skład Zarządu nie mogą zostać powołane osoby skazane
                  prawomocnym wyrokiem za przestępstwo umyślne ścigane
                  oskarżenia publicznego lub przestępstwo skarbowe.
                </li>
              </ol>
              <div className="text-xl self-center">§ 22</div>
              <p>Do zakresu działania Zarządu Stowarzyszenia należy:</p>
              <ol className="list-decimal px-8">
                <li>
                  Kierowanie bieżącą działalnością Stowarzyszenia w oparciu o
                  Statut i zarządzanie jego majątkiem.
                </li>
                <li>Realizacja uchwał Walnego Zebrania Członków.</li>
                <li>
                  Realizacja zobowiązań w imieniu Stowarzyszenia w tym nabywanie
                  i zbywanie mienia ruchomego.
                </li>
                <li>Zwoływanie Walnego Zebrana Członków.</li>
                <li>
                  Składanie sprawozdań ze swojej działalności na Walnym Zebraniu
                  Członków.
                </li>
                <li>
                  Podejmowane uchwał w sprawie przyjęcia nowych członków
                  Stowarzyszenia, oraz uchwał w sprawie wykluczenia członka, a
                  także pozbawienia godności członka honorowego.
                </li>
                <li>Uchwalenie wysokości składek członkowskich.</li>
              </ol>
              <div className="text-xl self-center">§ 23</div>
              <p>Komisja Rewizyjna</p>
              <ol className="list-decimal px-8">
                <li>
                  Komisja Rewizyjna jest organem Stowarzyszenia powołanym do
                  sprawowania kontroli nad jego działalnością.
                </li>
                <li>
                  Komisja Rewizyjna składa się z trzech członków, w tym
                  Przewodniczącego, Zastępcy Przewodniczącego i Członka Komisji
                  Rewizyjnej.
                </li>
                <li>
                  O podziale funkcji Zastępcy Przewodniczącego i Członka Komisji
                  Rewizyjnej decydują członkowie Komisji między sobą.
                </li>
                <li>
                  Uchwały Komisji Rewizyjnej podejmowane są w głosowaniu jawnym,
                  bezwzględną większością głosów przy obecności pełnego składu
                  trzech osób.
                </li>
                <li>
                  Członkowie Komisji Rewizyjnej nie mogą być członkami Zarządu,
                  ani pozostawać z nimi w związku małżeńskim, we wspólnym
                  pożyciu, w stosunku pokrewieństwa, powinowactwa lub
                  podległości służbowej.
                </li>
                <li>
                  Członkami Komisji Rewizyjnej nie mogą być osoby skazane
                  prawomocnym wyrokiem za przestępstwo umyślne ścigane z
                  oskarżenia publicznego lub przestępstwo skarbowe.
                </li>
                <li>
                  Członkowie Komisji Rewizyjnej nie otrzymują wynagrodzenia ani
                  zwrotu kosztów w związku z wykonywana funkcją.
                </li>
              </ol>
              <div className="text-xl self-center">§ 24</div>
              <p>
                Do zakresu działania Komisji Rewizyjnej należy przeprowadzone co
                najmniej raz w roku kontroli Zarządu pod względem merytorycznym
                i finansowym poprzez:
              </p>
              <ol className="list-decimal px-8">
                <li>Kontrolowanie całokształtu działalności Stowarzyszenia.</li>
                <li>
                  Ocena pracy Zarządu, w tym corocznych sprawozdań i bilansu.
                </li>
                <li>
                  Występowanie do Zarządu z wnioskami wynikającymi z
                  przeprowadzonej kontroli.
                </li>
                <li>
                  Prawo zwoływania nadzwyczajnego Walnego Zebrania Członków w
                  razie stwierdzenia niewywiązywania się przez Zarząd jego
                  statutowych obowiązków, albo w innym szczególnym przypadku, a
                  także prawo żądania zwołania posiedzenia Zarządu.
                </li>
                <li>
                  Zwołanie Walnego Zebrana Członków w razie nie zwołania go
                  przez Zarząd w terminie ustalonym statutem.
                </li>
                <li>
                  Prowadzenie okresowych kontroli opłacania składek
                  członkowskich.
                </li>
                <li>
                  Składanie sprawozdania ze swojej działalności na Walnym
                  Zebraniu Członków.
                </li>
                <li>
                  Wnioskowanie do Walnego Zebrania Członków udzielenia
                  absolutorium dla Zarządu.
                </li>
              </ol>
              <div className="text-xl self-center">§ 25</div>
              <p>
                Członkami Komisji Rewizyjnej nie mogą być członkowie Zarządu ani
                osoby pozostające z nimi w stopniu pokrewieństwa, związku
                małżeńskim , czy we wspólnym pożyciu
              </p>
            </div>
            {/* ROZDZIAL 5 - START */}
            <div className="flex flex-col gap-4 mb-12">
              <div className="text-xl self-center">
                Rozdział V — Majątek Stowarzyszenia
              </div>
              <div className="text-xl self-center">§ 26</div>
              <p>
                Majątek Stowarzyszenia mogą stanowić nieruchomości, ruchomości i
                środki pieniężne, które w całości służą celom statutowym.
              </p>
              <div className="text-xl self-center">§ 27</div>
              <p>
                Cały dochód organizacji przeznaczony jest na cele statutowe i
                może pochodzić:
              </p>
              <ol className="list-decimal px-8">
                <li>Ze składek członkowskich</li>
                <li>Z zapisów, darowizn, spadków</li>
                <li>Z dochodów z ofiarności publicznej</li>
                <li>
                  Z dotacji, kontraktów, grantów, środków z funduszów
                  publicznych
                </li>
                <li>
                  Z przeprowadzonych akcji charytatywnych (np. koncerty,
                  festyny, imprezy artystyczno - sportowe itp.)
                </li>
                <li>Z odpłatnej działalności pożytku publicznego:</li>
                <ul className="list-disc">
                  <li>
                    Sprzedaż w różnej formie artykułów z ofiarności publicznej
                    nie wykorzystanej bezpośrednio do celów statutowych
                  </li>
                  <li>
                    Sprzedaż w różnej formie artykułów dekoracyjnych i
                    użytkowych wykonanych w formie rękodzieła przez
                    wolontariuszy
                  </li>
                  <li>
                    Sprzedaż kalendarzy, publikacji, artykułów, książek i
                    materiałów edukacyjnych własnego autorstwa
                  </li>
                  <li>
                    Świadczenie usług związanych z opieką nad zwierzętami w
                    zakresie wyżywienia, pielęgnowania, strzyżenia, pilnowania i
                    tresury
                  </li>
                  <li>Prowadzenie szkoleń i działań edukacyjnych</li>
                </ul>
              </ol>
              <div className="text-xl self-center">§ 28</div>
              <ol className="list-decimal px-8">
                <li>
                  Składki członkowskie powinny być opłacane comiesięcznie do
                  ostatniego dnia miesiąca, nie rzadziej niż raz na 3 miesiące
                </li>
                <li>
                  Stowarzyszenie prowadzi gospodarkę finansową oraz rachunkowość
                  zgodnie z obowiązującymi przepisami
                </li>
              </ol>
              <div className="text-xl self-center">§ 29</div>
              <ol className="list-decimal px-8">
                <li>
                  Następujące czynności majątkiem wymagają zgody Walnego
                  Zebrania:
                </li>
                <ul className="list-disc">
                  <li>Zbycie, nabycie, lub obciążenie nieruchomości.</li>
                  <li>Zaciągnięcie kredytu, lub pożyczki.</li>
                  <li>
                    Rozporządzenie składnikiem majątku o wartości powyżej 10 000
                    zł.
                  </li>
                </ul>
                <li>Zabronione jest:</li>
                <ul className="list-disc">
                  <li>
                    Udzielanie pożyczek lub zabezpieczenia zobowiązań mieniem
                    Stowarzyszenia w stosunku do jej członków, członków organów
                    lub pracowników oraz osób, z którymi pozostają oni w związku
                    małżeńskim, lub we wspólnym pożyciu albo w stosunku
                    pokrewieństwa lub powinowactwa w linii prostej, pokrewieństw
                    lub powinowactwa w linii bocznej do drugiego stopnia albo są
                    związani z tytułu przysposobienia, opieki lub kurateli,
                    zwanych dalej „osobami bliskimi”.
                  </li>
                  <li>
                    Przekazywania majątku Stowarzyszenia na rzecz jego członków,
                    członków organów, lub pracowników oraz innych osób bliskich,
                    na zasadach innych niż w stosunku do osób trzecich, w
                    szczególności, jeżeli przekazanie to następuje bezpłatnie
                    lub na preferencyjnych warunkach.
                  </li>
                  <li>
                    Wykorzystywania majątku na rzecz członków, członków organów
                    lub pracowników oraz bliskich na zasadach innych niż w
                    stosunku do osób trzecich, chyba, ze to wykorzystanie wynika
                    ze statutowego celu.
                  </li>
                  <li>
                    Zakup towarów i usług od podmiotów, w których uczestniczą
                    członkowie Stowarzyszenia, członkowie organów lub pracownicy
                    oraz ich osób bliskich , na zasadach innych niż w stosunku
                    do osób trzecich lub po cenach wyższych niż rynkowe.
                  </li>
                </ul>
              </ol>
            </div>
            {/* ROZDZIAL 6 - START */}
            <div className="flex flex-col gap-4 mb-12">
              <div className="text-xl self-center">
                Rozdział VI — Zmiana Statutu i rozwiązanie Stowarzyszenia
              </div>
              <div className="text-xl self-center">§ 30</div>
              <ol className="list-decimal px-8">
                <li>
                  Uchwały o zmianie statutu Stowarzyszenia oraz uchwałę o
                  rozwiązaniu Stowarzyszenia podejmuje Walne Zgromadzenia
                  Członków Stowarzyszenia bezwzględną większością głosów, przy
                  obecności co najmniej połowy członków.
                </li>
                <li>
                  Wraz z uchwałą o rozwiązaniu Stowarzyszenia Walne Zebranie
                  Członków Stowarzyszenia podejmuje uchwałę o przeznaczeniu
                  majątku Stowarzyszenia.
                </li>
                <li>
                  Uchwała o przeznaczeniu majątku dla swej ważności wymaga
                  zatwierdzenia przez Komisje Rewizyjną.
                </li>
                <li>
                  W przypadku nie podjęcia takiej uchwały przez Walne Zebranie
                  Członków Stowarzyszenia majątek Stowarzyszenia przechodzi na
                  Schronisko dla Bezdomnych Zwierząt w Józefowie.
                </li>
                <li>
                  Likwidatorami są członkowie Zarządu, chyba, ze Walne Zebranie
                  Członków Stowarzyszenia wyznaczy inne osoby.
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Statute;
