import React from "react";
import logo from "../assets/logo-bezdomniaki-biały.svg";

function Logo() {
  return (
    <div className="logosize hidden sm:block">
      <img src={logo} alt="Logo" />
    </div>
  );
}

export default Logo;
