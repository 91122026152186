import React from "react";
import bg1 from "../assets/adoptiondog2.jpg";
import bg2 from "../assets/adoptiondog1.jpg";
import bg3 from "../assets/adoptioncat1.jpg";
import { FaFacebook, FaInstagram, FaYoutube } from "react-icons/fa";
import { FaPaw } from "react-icons/fa";
import dog from "../assets/dog.svg";

function Help() {
  return (
    <main>
      <div className="container mx-auto">
        <div className="flex flex-col relative z-20">
          <FaPaw
            style={{
              position: "absolute",
              top: "25%",
              right: "10%",
              zIndex: "-1",
              fill: "#F87272",
              opacity: "0.4",
              transform: "rotate(40deg)",
            }}
            className="bigIcon"
          />
          <div className="warningBarIcon2">
            <img className="dogIcon" src={dog} alt="Ikona - Pies" />
          </div>
          <h1 className="text-2xl md:text-3xl lg:text-4xl font-extrabold py-12">
            Jak nam pomóc?
          </h1>
          <p className="text-lg md:text-xl lg:text-2xl font-bold mb-24 w-3/5">
            Naszym podopiecznym można pomóc na kilka sposobów &darr;
          </p>
          <div className="flex justify-around mb-24 gap-4 flex-col lg:flex-row">
            <div className="flex flex-col gap-4 p-8 shadow-lg rounded-lg">
              <h2 className="text-xl md:text-2xl font-extrabold text-error">
                Darowizna:
              </h2>
              <p className="text-lg md:text-xl font-bold">
                Bank Pekao SA:{" "}
                <span className="text-error font-extrabold">
                  18 1240 3480 1111 0010 7213 5490
                </span>
              </p>
              <p className="text-lg md:text-xl font-bold">
                Tytuł:{" "}
                <span className="text-error font-extrabold">
                  Darowizna na cele statutowe
                </span>
              </p>
            </div>

            <div className="flex flex-col gap-4 p-8 shadow-lg rounded-lg relative z-20">
              <h2 className="text-xl md:text-2xl font-extrabold text-error">
                1,5% podatku:
              </h2>
              <p className="text-lg md:text-xl font-bold">
                Rozliczając swój PIT <br /> Wystarczy wpisać nr KRS:{" "}
                <span className="text-error font-extrabold">0000657844</span>
              </p>
              <p className="text-lg md:text-xl font-bold">
                Cel szczegółowy:{" "}
                <span className="font-extrabold">Pomoc dla Stowarzyszenia</span>
              </p>
              <div
                style={{
                  position: "absolute",
                  margin: "0 auto",
                }}
                className="banner"
              >
                <a
                  href="https://www.pitax.pl/rozliczenie-pit-online-0000657844"
                  title="Pobierz program PIT"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="https://www.iwop.pl/_resources/themes/simple/images/bannery/banner3.svg"
                    style={{ width: "100%" }}
                    alt="Pobierz program PIT"
                  />
                </a>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-8 relative z-20 mb-12">
            <FaPaw
              style={{
                position: "absolute",
                bottom: "0",
                left: "5%",
                zIndex: "-1",
                fill: "#FBBD23",
                opacity: "0.3",
                transform: "rotate(30deg)",
              }}
              className="bigIcon"
            />
            <h2 className="text-2xl md:text-3xl lg:text-4xl font-extrabold py-12">
              Oddaj ubrania
            </h2>
            <p className="text-lg md:text-xl lg:text-2xl font-bold">
              Robiąc porządki w szafie też możesz pomóc 😊
            </p>
            <p className="text-md sm:text-lg md:text-xl font-medium text-neutral md:w-3/4 leading-relaxed">
              Pakujesz ubrania w karton, na stronie{" "}
              <a
                target="_blank"
                rel="noreferrer"
                className="link link-warning font-bold"
                href="https://www.ubraniadooddania.pl/campaigns/1343"
              >
                Ubrania do oddania
              </a>{" "}
              wybierasz nasze Stowarzyszenie i zamawiasz kuriera, który za darmo
              odbierze od Ciebie paczkę.
              <br />
              <br />
              <span className="text-error font-bold">
                Prawda, że proste? Pomagasz nie tylko nam, ale również naszej
                planecie 💖
              </span>
              <br />
              <br />
              Szczegóły jakie ubrania są zbierane dostępne są na{" "}
              <a
                target="_blank"
                rel="noreferrer"
                className="link link-warning font-bold"
                href="https://www.ubraniadooddania.pl/campaigns/1343"
              >
                Ubrania do oddania
              </a>
            </p>
          </div>

          <div className="flex flex-col gap-8 relative z-20">
            <FaPaw
              style={{
                position: "absolute",
                bottom: "0",
                left: "5%",
                zIndex: "-1",
                fill: "#FBBD23",
                opacity: "0.3",
                transform: "rotate(30deg)",
              }}
              className="bigIcon"
            />
            <h2 className="text-2xl md:text-3xl lg:text-4xl font-extrabold py-12">
              Pomoc rzeczowa
            </h2>
            <p className="text-lg md:text-xl lg:text-2xl font-bold">
              Zorganizuj zbiórkę na swoich urodzinach, ślubie, w szkole, w
              pracy...
            </p>
            <p className="text-md sm:text-lg md:text-xl font-medium text-neutral md:w-3/4 leading-relaxed">
              Psy w kojcach mają inne potrzeby żywieniowe niż psy mieszkające w
              domach. Zyją w stresie, w gorszych warunkach, w dużych skupiskach,
              gdzie łatwiej przenoszą się choroby. Niektóre z nich są chore albo
              przechodzą rekonwalescencję po wypadkach. <br /> Musimy im dawać
              lepszej jakości karmę, ponieważ ta gorsza, najtańsza im nie służy.{" "}
              <br />
              Lepsza karma pozwala na szybszy powrót do zdrowia i życie w
              lepszej kondycji.
              <br />
              <br />
              <span className="font-bold">
                Dlatego jeśli chcecie pomóc, kupcie im dobrą karmę z dużą
                zawartością mięsa. Nie martwcie się, że przynosicie dwie puszki
                zamiast 10-kilogramowego worka, który kupilibyście w tej samej
                cenie.
              </span>{" "}
              <span className="text-lg sm:text-xl md:text-2xl font-bold text-error">
                <br />
                <br /> Ważna jest jakość - nie ilość.
              </span>
            </p>
            <p className="text-lg sm:text-xl md:text-2xl font-bold mt-12 md:w-3/4">
              Psy i koty jedzą u nas karmy z listy poniżej, dzięki temu szybciej
              wracają do formy.
            </p>
            <p className="text-lg sm:text-xl font-medium text-neutral md:w-3/4 mb-12">
              Jeśli otrzymamy jednak karmę spoza niej -{" "}
              <span className="font-bold text-error">nie zmarnuje się!</span>{" "}
              przekażemy ją wówczas karmicielom :)
            </p>
            <div className="flex justify-around mb-24 gap-4 flex-col lg:flex-row">
              <div className="flex flex-col gap-6 shadow-lg rounded-lg p-8">
                <h3 className="text-xl md:text-2xl font-extrabold text-error self-center">
                  Dary dla psów
                </h3>
                <div className="flex justify-center gap-4">
                  <div className="flex flex-col gap-3">
                    <div className="text-lg md:text-xl font-extrabold">
                      Karma sucha:
                    </div>
                    <div className="text-md md:text-lg font-bold">
                      Josera SensiPlus
                    </div>
                    <div className="text-md md:text-lg font-bold">
                      Josera Lamm & Rise
                    </div>
                    <div className="text-md md:text-lg font-bold">
                      Josera Light & Vital
                    </div>
                    <div className="text-md md:text-lg font-bold">
                      Fitmin Adult
                    </div>
                    <div className="text-md md:text-lg font-bold">
                      Britt Care
                    </div>
                  </div>
                  <div className="flex flex-col gap-3">
                    <div className="text-lg md:text-xl font-extrabold">
                      Karma mokra:
                    </div>
                    <div className="text-md md:text-lg font-bold">
                      Dolina Noteci
                    </div>
                    <div className="text-md md:text-lg font-bold">
                      Animonda Gran Carno
                    </div>
                    <div className="text-md md:text-lg font-bold">Rocco</div>
                  </div>
                </div>
                <div className="text-md md:text-lg font-bold text-error">
                  Zabawki, gryzaki, smaczki, olej z łososia, szelki typu Guard,
                  długie smycze, ubranka dla średnich psów
                </div>
              </div>
              <div className="flex flex-col gap-6 shadow-lg rounded-lg p-8">
                <h3 className="text-xl md:text-2xl font-extrabold text-error self-center">
                  Dary dla kotów
                </h3>
                <div className="flex justify-center gap-4">
                  <div className="flex flex-col gap-3">
                    <div className="text-lg md:text-xl font-extrabold">
                      Karma sucha:
                    </div>
                    <div className="text-md md:text-lg font-bold">Smilla</div>
                  </div>
                  <div className="flex flex-col gap-3">
                    <div className="text-lg md:text-xl font-extrabold">
                      Karma mokra:
                    </div>
                    <div className="text-md md:text-lg font-bold">Smilla</div>
                    <div className="text-md md:text-lg font-bold">Animonda</div>
                  </div>
                </div>
                <div className="text-md md:text-lg font-bold text-error mt-auto">
                  Żwirek dla kotów (np. Cat's Best)
                  <br />
                  Suplementy: Genomune na odporność
                  <br />
                  Probiotyki: BioProtect, FortiFlora, FloraBalance
                </div>
              </div>
            </div>
            <div className="bg-error text-base-100 p-12 rounded-lg shadow-md mb-24 self-end">
              <div className="text-xl md:text-2xl lg:text-3xl font-extrabold mb-12">
                Poza tym bardzo przydadzą nam się:
              </div>
              <div className="text-md md:text-lg lg:text-xl font-medium w-3/4">
                Środki czystości:
                <br />
                Ręczniki papierowe, płyn do naczyń, Domestos
                <br /> Worki na odpady 120L
                <br /> Poszwy
                <br /> Prześcieradła
                <br /> Koce
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-12">
          <h2 className="text-2xl md:text-3xl lg:text-4xl font-extrabold py-2 md:py-12">
            FaniMani
          </h2>
          <div className="text-md md:text-xl font-bold">
            <span className="text-xl md:text-2xl text-error">Pomożesz</span> —
            robiąc codzienne zakupy w serwisie <strong>FaniMani</strong>
          </div>
          <div className="flex flex-col bg-error self-center lg:self-end rounded-lg shadow-md gap-6 p-8 lg:w-1/2 mb-24">
            <h3 className="text-xl md:text-2xl text-base-100 font-extrabold ">
              Jak to działa?
            </h3>
            <p className="text-lg md:text-xl text-base-100 font-medium">
              Wchodzisz na stronę{" "}
              <strong>
                <a
                  href="https://fanimani.pl/stowarzyszeniepomiechowskiebezdomniaki/"
                  target="_blank"
                  rel="noreferrer"
                  className="link link-warning"
                >
                  FaniMani
                </a>
              </strong>
              , wybierasz tam nasze Stowarzyszenie, a następnie robisz zakupy w
              wybranym przez siebie sklepie, np. Zooplus, Empik, Booking,
              Zalando — <span className="font-bold">gdziekolwiek</span>!
            </p>
            <p className="text-lg md:text-xl text-base-100 font-medium">
              Płacisz za wybrane produkty jak zawsze, ale część wartości z
              Twoich zakupów FaniMani przekazuje do naszego Stowarzyszenia.
            </p>
            <p className="text-lg md:text-xl text-base-100 font-medium">
              Na tym całe zadanie się kończy — prawda, że to łatwe? 🙂
              <br />
              Nie zapominajcie o nas przy codziennych zakupach, wchodźcie na
              Wasze ulubione sklepy za pośrednictwem <strong>FaniMani</strong>
            </p>
          </div>
          <div className="flex flex-col gap-6 mb-12 md:mb-24 relative z-20">
            <FaPaw
              style={{
                position: "absolute",
                bottom: "0",
                left: "35%",
                zIndex: "-1",
                fill: "#FBBD23",
                opacity: "0.3",
                transform: "rotate(-40deg)",
              }}
              className="bigIcon"
            />
            <h2 className="text-2xl md:text-3xl lg:text-4xl font-extrabold py-12">
              Wolontariat
            </h2>
            <p className="text-lg md:text-xl lg:text-2xl font-bold md:w-3/4">
              Jeśli jesteś osobą, której nie jest obojętny los zwierząt i chcesz
              aktywnie uczestniczyć w pomocy, masz głowę pełną pomysłów, nie
              boisz się nowych wyzwań —{" "}
              <strong className="text-error">
                to Ciebie właśnie potrzebujemy!
              </strong>
            </p>
            <p className="text-lg md:text-xl font-medium text-neutral md:w-3/4 leading-relaxed">
              Spacery, zabawa i socjalizacja są kluczowe dla psów na znalezienie
              domów. To właśnie wtedy powstają opisy charakterów psów, zdjęcia i
              filmy. Spacery odbywają się w każdą sobotę i niedzielę w godzinach
              09:00 - 11:00
            </p>
          </div>

          <div className="flex flex-col gap-6 mb-24 relative z-20">
            <FaPaw
              style={{
                position: "absolute",
                top: "35%",
                right: "5%",
                zIndex: "-1",
                fill: "#FBBD23",
                opacity: "0.3",
                transform: "rotate(-110deg)",
              }}
              className="bigIcon"
            />
            <h2 className="text-2xl md:text-3xl lg:text-4xl font-extrabold py-12">
              Dom Tymczasowy
            </h2>
            <p className="text-lg md:text-xl lg:text-2xl font-bold md:w-3/4">
              Dom zawsze będzie lepszy od niewielkiego kojca, dlatego ta forma
              pomocy jest bardzo istotna.
              <strong className="text-error">
                W domu jest spokojniej, cieplej i szybciej się wraca do zdrowia.
              </strong>
            </p>
            <p className="text-xl font-medium text-neutral md:w-3/4 leading-relaxed">
              Naszym domom tymczasowym zapewniamy karmę, akcesoria i opiekę
              weterynaryjną.
              <br />
              <br />
              <strong className="text-error">Tymczasowy</strong> — czyli na jaki
              okres?
              <br />
              <br />
              <span className="font-semibold">
                Do czasu znalezienia domu stałego.
              </span>
              <br />
              <br />
              <span className="font-semibold">
                Na czas leczenia po zabiegach, wypadkach etc.
              </span>
              <br />
              <br />
              <span className="font-semibold">
                <strong>Do momentu odejścia psa za Tęczowy Most</strong>. To
                trudne dla opiekunów, ale bez nich pies odszedłby samotny w
                kojcu.
              </span>
            </p>
          </div>

          <div className="flex justify-around mb-24 gap-4 flex-col items-center lg:flex-row lg:items-stretch relative z-20">
            <FaPaw
              style={{
                position: "absolute",
                top: "35%",
                right: "5%",
                zIndex: "-1",
                fill: "#FBBD23",
                opacity: "0.3",
                transform: "rotate(-110deg)",
              }}
              className="bigIcon"
            />
            <FaPaw
              style={{
                position: "absolute",
                bottom: "35%",
                left: "5%",
                zIndex: "-1",
                fill: "#FBBD23",
                opacity: "0.3",
                transform: "rotate(110deg)",
              }}
              className="bigIcon"
            />
            <div className="card w-96 bg-base-100 shadow-xl image-full">
              <figure>
                <img src={bg1} alt="Zdjęcie Psa" />
              </figure>
              <div className="card-body">
                <h2 className="card-title font-extrabold">Social Media</h2>
                <p className="font-semibold">
                  Udostępniaj nasze posty, wydarzenia, aby jak najwięcej osób
                  dowiedziało się o naszych podopiecznych i o naszej
                  działalności.
                  <br />
                  <span className="text-error font-bold">
                    To najprostsza forma pomocy.
                  </span>
                </p>
                <div className="card-actions justify-end btn-sm gap-0">
                  <a
                    href="https://www.youtube.com/channel/UCd1Al4UjmRtSCFOVIvVVJSA"
                    target="_blank"
                    rel="noreferrer"
                    className="btn btn-ghost"
                  >
                    <FaYoutube className="cardIcon" />
                  </a>
                  <a
                    href="https://www.instagram.com/pomiechowskie.bezdomniaki/?hl=pl"
                    target="_blank"
                    rel="noreferrer"
                    className="btn btn-ghost"
                  >
                    <FaInstagram className="cardIcon" />
                  </a>
                  <a
                    href="https://www.facebook.com/pomiechowskie.bezdomniaki/"
                    target="_blank"
                    rel="noreferrer"
                    className="btn btn-ghost"
                  >
                    <FaFacebook className="cardIcon" />
                  </a>
                </div>
              </div>
            </div>

            <div className="card w-96 bg-base-100 shadow-xl image-full">
              <figure>
                <img src={bg3} alt="Zdjęcie Kota" />
              </figure>
              <div className="card-body">
                <h2 className="card-title font-extrabold">
                  Odkręć los Pomiechowskiego Bezdomniaka
                </h2>
                <p className="font-semibold">
                  Zbieraj nakrętki od napojów. <br />
                  Zebrane nakrętki możesz oddać w kilku punktach:
                  <br />
                  <br />W Pomiechówku — Lecznica wterynaryjna ul. Wojska
                  Polskiego lub Ośrodek Zdrowia ul Słoneczna
                  <br />
                  <br />W Nowym Dworze Mazowieckim: CH Hit — sklep Zoologiczny
                </p>
              </div>
            </div>

            <div className="card w-96 bg-base-100 shadow-xl image-full">
              <figure>
                <img src={bg2} alt="Zdjęcia psa" />
              </figure>
              <div className="card-body">
                <h2 className="card-title font-extrabold">Bazarek</h2>
                <p className="font-semibold">
                  Na naszym Bazarku możecie kupić ciekawe fanty i tym sposobem
                  wspierać naszych podopiecznych. <br />
                  <br />
                  <strong className="text-error">
                    Możecie również przekazać nam fanty na licytację.
                  </strong>
                </p>
                <a
                  href="https://www.facebook.com/groups/247897273176886"
                  className="link link-error"
                  rel="norefferer"
                >
                  Bazarek Stowarzyszenia
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Help;
