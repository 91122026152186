import React from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

function Loading() {
  return (
    <div
      className="fixed top-0 left-0 loading text-primary w-screen h-screen z-50"
      style={{ backgroundColor: "rgba(0, 0, 0, 0.7)" }}
    >
      <div className="text-7xl font-extrabold">
        <AiOutlineLoading3Quarters
          style={{
            height: "100px",
            width: "100px",
          }}
          className="text-primary animate-spin"
        />
      </div>
    </div>
  );
}

export default Loading;
