import React from "react";
import dog from "../assets/dog.svg";

function WarningBar() {
  return (
    <section className="bg-error py-24 relative z-10">
      <div className="warningBarIcon">
        <img className="dogIcon" src={dog} alt="Ikona - Pies" />
      </div>
      <div className="container mx-auto">
        <div className="flex flex-col gap-12">
          <h2 className="text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-bold text-base-100 self-center">
            Właścicielu! Zadbaj o swojego psa i kota!
          </h2>
          <p className="text-md md:text-lg xl:text-xl font-medium text-base-100 lg:w-3/4 leading-relaxed px-8">
            <span className="text-lg md:text-xl xl:text-2xl font-bold text-neutral">
              ZACZIPUJ
            </span>{" "}
            - W Gminie Pomiechówek od 2012 roku możesz to zrobić bezpłatnie.
            Zaczipowane zwierzę trafia do bazy Safe Animal
          </p>
          <p className="text-md md:text-lg xl:text-xl font-medium text-base-100 lg:w-3/4 leading-relaxed px-8">
            <span className="text-lg md:text-xl xl:text-2xl font-bold text-neutral">
              WYKASTRUJ
            </span>{" "}
            - Gmina Pomiechówek od 2020 roku wspiera kastracje zwierząt
            właścicielskich. Corocznie na ten cel są wyznaczane określone
            środki. Nasze Stowarzyszenie od samego początku również w miarę
            możliwości wspiera kastracje. Aktualna sytuacja finansowa pozwala
            nam nie odmawiać żadnej osobie, która o taką pomoc poprosi.
          </p>
        </div>
      </div>
    </section>
  );
}

export default WarningBar;
