import React from "react";
import hero from "../assets/homelesshero.jpg";
import Paws from "../components/Paws";
import adoption from "../assets/adoption.jpg";
import dogwaiting from "../assets/dogwaiting.jpg";
import chip from "../assets/chip.jpg";
import ster from "../assets/sterilization.jpg";
import { FaPaw } from "react-icons/fa";

function Homeless() {
  return (
    <main>
      <section className="bg-primary">
        <div
          className="homelessHero relative"
          style={{
            backgroundImage: `linear-gradient(to bottom right, rgba(92, 127, 103, 0.1), rgba(92, 127, 103, 0.05)), url(${hero})`,
            borderBottom: "1px solid rgba(248, 114, 114, 0.3)",
          }}
        >
          <h1 className="text-4xl md:text-5xl lg:text-6xl leading-tight xl:text-7xl font-bold serif italic pt-16 pl-24 xl:leading-tight opacity-90 cursor-default">
            Z bezdomnością
            <br /> <span className="text-error">trzeba walczyć!</span>
          </h1>
          <Paws className="homelessPaws" />
        </div>

        <div className="bg-base-100 py-24">
          <div className="container mx-auto">
            <div className="flex flex-col gap-24 mb-36">
              <h2 className="text-3xl lg:text-4xl 2xl:text-5xl serif italic font-black pl-12">
                Nie kupuj. <span className="text-error">Adoptuj!</span>
              </h2>
              <div className="flex flex-col xl:flex-row gap-12 xl:gap-0 justify-between relative z-20">
                <FaPaw
                  style={{
                    position: "absolute",
                    bottom: "-30%",
                    left: "10%",
                    zIndex: "-1",
                    fill: "#FBBD23",
                    opacity: "0.3",
                    transform: "rotate(40deg)",
                  }}
                  className="bigIcon"
                />
                <div className="text-md lg:text-lg 2xl:text-xl text-neutral font-normal">
                  <p className="lg:w-3/4 leading-relaxed">
                    Nie kupuj psów, zwłaszcza z pseudo hodowli. Psów w typie
                    rasowych. Napędzasz tylko machinę produkcji zwierząt.
                    <br />
                    <br />
                    <span className="text-error font-medium">
                      Adoptuj Bezdomniaka, najlepiej z Pomiechówka
                    </span>{" "}
                    🙂
                    <br />
                    <br />
                    Nie możesz adoptować? Daj dom tymczasowy! Zaopiekuj się
                    czasowo psiakiem, zanim znajdziemy dla niego dom.
                    <br />
                    <br />
                    Nie możesz adoptować, ani zaoferować domu tymczasowego? 
                    <strong>Udostępnij nasze ogłoszenia</strong>. Może Twoi
                    znajomi rozważą nowego czworonożnego przyjaciela.
                  </p>
                </div>
                <div className="max-w-xl 2xl:max-w-2xl border-2 border-error rounded-lg overflow-hidden flex-none self-start">
                  <img src={adoption} alt="Zdjęcie Psa" />
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-24">
              <h2 className="text-3xl lg:text-4xl 2xl:text-5xl serif italic font-black pr-28 self-end">
                <span className="text-error">Reaguj</span> na porzucenie!
              </h2>
              <div className="flex flex-col gap-12 xl:gap-0 justify-between xl:flex-row-reverse">
                <div className="text-md lg:text-lg 2xl:text-xl text-neutral font-normal xl:pl-40">
                  <p className="leading-relaxed">
                    Reaguj, gdy widzisz moment porzucenia z samochodu lub
                    przywiązania psa w celu jego porzucenia.
                    <br />
                    <br />
                    <span className="text-error font-medium">
                      Porzucenie jest przestępstwem i powinno być ukarane!
                    </span>
                    <br />
                    <br />
                    Zgłoś przestępstwo na policję podając jak najwięcej
                    szczegółów miejsce, nr rejestracyjny, marka samochodu etc.
                    <br />
                    <br />
                    Art.6 ust.2 p.11 ustawy o ochronie zwierząt:
                    <br />
                    <span className="text-lg font-light italic opacity-85">
                      "Przez znęcanie się nad zwierzętami należy rozumieć
                      zadawanie albo świadome dopuszczanie do zadawania bólu lub
                      cierpień, a w szczególności (...) porzucanie zwierzęcia, a
                      w szczególności psa lub kota, przez właściciela lub inną
                      osobę, pod której opieką zwierzę pozostaje."
                    </span>
                  </p>
                </div>
                <div className="max-w-xl 2xl:max-w-2xl border-2 border-error rounded-lg overflow-hidden flex-none self-start">
                  <img src={dogwaiting} alt="Zdjęcie Psa" />
                </div>
              </div>

              <div className="flex flex-col gap-24 mb-36 relative">
                <Paws className="chipPaws" />
                <h2 className="text-3xl lg:text-4xl 2xl:text-5xl serif italic font-black pl-12">
                  Czipowanie
                </h2>
                <div className="flex flex-col xl:flex-row gap-12 xl:gap-0 justify-between relative z-20">
                  <FaPaw
                    style={{
                      position: "absolute",
                      bottom: "-10%",
                      left: "15%",
                      zIndex: "-1",
                      fill: "#FBBD23",
                      opacity: "0.3",
                      transform: "rotate(-25deg)",
                    }}
                    className="bigIcon"
                  />
                  <div className="text-md lg:text-lg 2xl:text-xl text-neutral font-normal">
                    <p className="lg:w-3/4 leading-relaxed">
                      Czipowanie to elektroniczne znakowanie zwierząt.
                      <br />
                      Mały micro chip jest wszczepiany podskórnie i pozwala
                      zidentyfikować zwierzę.
                      <br /> Za pomocą czytnika czipów odczytujemy numer. Jeżeli
                      jest on wprowadzony wcześniej do bazy np. safe animals, w
                      prosty sposób możemy uzyskać kontakt do właściciela.
                      <br />
                      <br />
                      Zgodnie z uchwałą Rady Gminy chipowanie zwierząt dla
                      właścicieli będących mieszkańcami gminy Pomiechówek jest
                      <br /> w{" "}
                      <strong className="text-error">
                        100% finansowane
                      </strong>{" "}
                      przez Urząd Gminy Pomiechówek.
                    </p>
                  </div>
                  <div className="xl:max-w-xl 2xl:max-w-2xl border-2 border-error rounded-lg overflow-hidden flex-none self-start">
                    <img src={chip} alt="Zdjęcie Kota" />
                  </div>
                </div>
              </div>

              <div className="flex flex-col gap-24 mb-36">
                <h2 className="text-3xl lg:text-4xl 2xl:text-5xl serif italic font-black pl-12">
                  Sterylizacja
                </h2>
                <div className="flex flex-col gap-12 xl:gap-0 justify-between xl:flex-row-reverse relative z-20">
                  <FaPaw
                    style={{
                      position: "absolute",
                      bottom: "-10%",
                      left: "15%",
                      zIndex: "-1",
                      fill: "#5C7F67",
                      opacity: "0.3",
                      transform: "rotate(40deg)",
                    }}
                    className="bigIcon"
                  />
                  <div className="text-md lg:text-lg 2xl:text-xl text-neutral font-normal xl:pl-40">
                    <p className="leading-relaxed">
                      Sterylizacja jest najskuteczniejszą formą ubezpłodnienia
                      zwierząt. Dzięki niej w sposób świadomy możemy mieć wpływ
                      i kontrolę nad rozrodem zwierząt w naszym otoczeniu.
                      <br />
                      <br />
                      Szczenięta, lub małe kocięta wyglądają rozkosznie, ale
                      zapewnienie im odpowiedzialnego domu jest nie lada
                      wyzwaniem. Zwłaszcza, gdy przychówek jest liczny.
                      <br />
                      <br />
                      <strong className="text-error">
                        Czy wiecie, ze największą grupę zwierząt porzucanych
                        stanowią psy w wieku późno-szczenięcym?
                      </strong>
                      <br />
                      <br />
                      <strong>
                        Czy suka lub kotka musi dla zdrowia wydać choć raz
                        potomstwo na świat?
                      </strong>
                      <br /> To stare i z palca wyssane porzekadło! Psy i koty
                      nie mają instynktu macierzyńskiego, a ciąża jest dla nich
                      jedynie obciążeniem dla kondycji fizycznej. Samice
                      zwierząt mają naturalny instynkt do prokreacji, ale nie
                      jest to instynkt macierzyński.
                      <br />
                      <br />
                      <strong>
                        Czy wykastrowany pies traci swój charakter?
                      </strong>
                      <br />
                      Usunięcie gruczołów wytwarzających testosteron ma wpływ na
                      ograniczenie instynktu do prokreacji. Pies zachowuje swój
                      osobliwy charakter. Nie ucieka jednak z posesji w
                      poszukiwaniu samic w rujce.
                      <br />
                      <br />
                      <strong>
                        <span className="text-error">
                          Kastracja i sterylizacja dodatkowo zabezpieczają
                          zwierzęta przed potencjalnymi schorzeniami
                          nowotworowymi narządów rozrodczych.
                        </span>
                      </strong>
                    </p>
                  </div>
                  <div className="max-w-xl 2xl:max-w-2xl border-2 border-error rounded-lg overflow-hidden flex-none self-start relative">
                    <Paws className="sterPaws" />
                    <img src={ster} alt="Zdjęcie Kotów" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Homeless;
