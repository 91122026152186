import React from "react";
import { FaFacebook, FaInstagram, FaYoutube } from "react-icons/fa";

function SocialBar() {
  return (
    <section className="py-20">
      <div className="flex items-center justify-center gap-24">
        <a
          href="https://www.facebook.com/pomiechowskie.bezdomniaki/"
          rel="noreferrer"
          target="_blank"
          className="hover:scale-105 transition-all"
        >
          <FaFacebook className="socialLink text-primary" />
        </a>
        <a
          href="https://www.instagram.com/pomiechowskie.bezdomniaki/?hl=pl"
          rel="noreferrer"
          target="_blank"
          className="hover:scale-105 transition-all"
        >
          <FaInstagram className="socialLink text-primary" />
        </a>
        <a
          href="https://www.youtube.com/channel/UCd1Al4UjmRtSCFOVIvVVJSA"
          rel="noreferrer"
          target="_blank"
          className="hover:scale-105 transition-all"
        >
          <FaYoutube className="socialLink text-primary" />
        </a>
      </div>
    </section>
  );
}

export default SocialBar;
