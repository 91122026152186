import React from "react";
import PetsNavbar from "../components/PetsNavbar";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Parallax, Scrollbar, A11y } from "swiper";
import "swiper/css";
import "swiper/css/bundle";
import slide1 from "../assets/petsslide1.jpg";
import slide2 from "../assets/petsslide2.jpg";
import pet from "../assets/pets1.jpg";
import AdoptDog from "../components/AdoptDog";
import AdoptCat from "../components/AdoptCat";
import Adopted from "../components/Adopted";

SwiperCore.use([Parallax, Autoplay, Scrollbar, A11y]);

function Pets() {
  const slides = [
    {
      img: slide1,
      title: "Zaginął Wam czworonożny przyjaciel?",
      pos: "11%",
    },
    {
      img: slide2,
      title: "Znaleźliście zwierzę i chcecie go ogłosić?",
      pos: "40%",
    },
  ];
  return (
    <main>
      <div className="petsSlider">
        <Swiper
          slidesPerView={1}
          scrollbar={{ draggable: true }}
          autoplay={{ autoplay: true, delay: 4000 }}
          parallax={{ parallax: true }}
          preventInteractionOnTransition={true}
          speed={800}
        >
          {slides.map((slide, i) => (
            <SwiperSlide key={i}>
              <div
                style={{
                  background: `linear-gradient(to bottom right, rgba(92, 127, 103,0.2), transparent), url(${slide.img})`,
                  backgroundPositionY: `${slide.pos}`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  position: "relative",
                }}
                className="petsMainSwiper"
              >
                {
                  <div className="absolute top-4 left-6">
                    <h2 className="text-2xl md:text-4xl lg:text-5xl leading-tight w-3/4 2xl:text-6xl 2xl:w-1/2 2xl:leading-tight serif font-bold text-base-100 select-none">
                      {slide.title}
                    </h2>
                  </div>
                }
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <section className="bg-primary py-24">
        <PetsNavbar />
        <div className="container mx-auto">
          <div className="flex justify-between xl:flex-row flex-col gap-12 xl:gap-0">
            <article className="flex flex-col">
              <h1 className="text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-bold serif text-base-100 py-24 px-12+">
                Zaginął Wam{" "}
                <strong className="text-warning">
                  czworonożny przyjaciel?
                </strong>
              </h1>
              <p className="text-sm md:text-md lg:text-lg xl:text-xl font-medium text-base-200 leading-relaxed">
                Pierwszym krokiem powinno być zgłoszenie go do Urzędu Miasta,
                lub Gminy.
                <br />W Pomiechówku to kontakt z wydziałem ochrony środowiska:{" "}
                <strong className="text-warning">22 765 27 22</strong>
                <br />
                <br />
                Stowarzyszenie nie zajmuje się odławianiem psów. Możemy jednak w
                szybki sposób pomóc zamieszczając ogłoszenie na naszym profilu
                na Facebooku.
                <br />
                <br />
                Piszcie przez kontakt w wiadomości prywatnej, lub bezpośrednio
                na adres:{" "}
                <a
                  href="mailto:pomiechowskie.bezdomniaki@gmail.com"
                  className="link link-warning"
                >
                  pomiechowskie.bezdomniaki@gmail.com
                </a>
                .
              </p>
            </article>
            <div className="max-w-2xl xl:max-w-xl border-2 border-base-300 overflow-hidden rounded-lg xl:self-center">
              <img src={pet} alt="Zdjęcie człowieka trzymającego psa" />
            </div>
          </div>
        </div>
      </section>
      <section className="bg-primary py-24">
        <div className="container mx-auto">
          <AdoptDog />
        </div>
      </section>
      <section className="bg-primary py-24">
        <div className="container mx-auto">
          <AdoptCat />
        </div>
      </section>
      <section className="bg-primary py-24">
        <div className="container mx-auto">
          <Adopted />
        </div>
      </section>
    </main>
  );
}

export default Pets;
