import React from "react";

function PetsNavbar() {
  return (
    <nav>
      <ul className="flex items-center justify-around text-base-100">
        <li>
          <a
            href="#psy"
            className="btn btn-warning btn-outline btn-sm sm:btn-md 2xl:btn-lg shadow-md"
          >
            Psy do adopcji
          </a>
        </li>
        <li>
          <a
            href="#koty"
            className="btn btn-warning btn-outline btn-sm sm:btn-md 2xl:btn-lg shadow-md"
          >
            Koty do adopcji
          </a>
        </li>
        <li>
          <a
            href="#znalazły-dom"
            className="btn btn-warning btn-outline btn-sm sm:btn-md 2xl:btn-lg shadow-md"
          >
            Znalazły dom
          </a>
        </li>
      </ul>
    </nav>
  );
}

export default PetsNavbar;
