import React from "react";
import { IoMdCalendar } from "react-icons/io";
import { MdOutlineEventAvailable } from "react-icons/md";
import { FaFacebook } from "react-icons/fa";

function NewsElement({ title, text, date }) {
  return (
    <div className="flex flex-col py-8 px-12 border-2 border-primary rounded-lg overflow-hidden shadow-md gap-12 bg-primary relative z-20">
      <a
        href="https://www.facebook.com/pomiechowskie.bezdomniaki/"
        rel="noreferrer"
        target="_blank"
        className=" hover:scale-105 transition-all"
        style={{
          position: "absolute",
          bottom: "5%",
          right: "3%",
          zIndex: "1",
        }}
      >
        <FaFacebook
          style={{
            height: "30px",
            width: "30px",
          }}
          className="text-base-100"
        />
      </a>
      <div className="flex items-start sm:items-center justify-between flex-col sm:flex-row">
        <div className="text-lg sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl font-extrabold text-base-100 flex items-center justify-center gap-4 truncate">
          {title}
          <MdOutlineEventAvailable className="text-secondary newsElementIcons" />
        </div>
        <div className="text-lg sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl font-bold sm:font-extrabold text-base-100 flex items-center justify-center gap-2 flex-row-reverse sm:flex-row">
          <IoMdCalendar className="text-secondary newsElementIcons" />
          {date}
        </div>
      </div>
      <div className="text-sm md:text-md lg:text-lg xl:text-xl font-normal lg:w-3/4 p-2 text-base-100 leading-relaxed">
        {text}
      </div>
    </div>
  );
}

export default NewsElement;
