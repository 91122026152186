import React from "react";
import ContactHero from "../components/sections/ContactHero";
import Statute from "../components/Statute";
import Reports from "../components/Reports";

function Contact() {
  return (
    <main>
      <ContactHero />
      <Statute />
      <Reports />
    </main>
  );
}

export default Contact;
