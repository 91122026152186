import React from "react";
import Hero from "../components/Hero";
import SectionIntro from "../components/sections/SectionIntro";
import SectionIntroTwo from "../components/sections/SectionIntroTwo";
import SocialBar from "../components/SocialBar";
import WarningBar from "../components/WarningBar";
import CTA from "../components/sections/CTA";

function Home() {
  return (
    <main>
      <Hero />
      <SectionIntro />
      <SectionIntroTwo />
      <SocialBar />
      <WarningBar />
      <CTA />
    </main>
  );
}

export default Home;
