import React, { useEffect, useState } from "react";
import Loading from "./Loading";
import { FaPaw } from "react-icons/fa";
import { GiPawHeart } from "react-icons/gi";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Scrollbar, A11y, EffectCreative } from "swiper";
import "swiper/css";
import "swiper/css/bundle";
import "swiper/css/effect-creative";

import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase.config";

SwiperCore.use([Autoplay, Scrollbar, A11y, EffectCreative]);

function Adopted() {
  const [loading, setLoading] = useState(true);
  const [adopted, setAdopted] = useState(null);

  useEffect(() => {
    const fetchAdopted = async () => {
      const docRef = collection(db, "adopted");
      const docSnap = await getDocs(docRef);
      const adoptedArr = [];

      docSnap.forEach((doc) => {
        return adoptedArr.push({
          data: doc.data(),
        });
      });

      setAdopted(adoptedArr);
      setLoading(false);
    };
    fetchAdopted();
  }, []);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div id="znalazły-dom" className="flex flex-col gap-12 relative z-20">
          <FaPaw
            style={{
              position: "absolute",
              top: "15%",
              right: "6%",
              zIndex: "-1",
              fill: "#E9E7E7",
              opacity: "0.3",
              transform: "rotate(40deg)",
            }}
            className="bigIcon"
          />
          <GiPawHeart
            style={{
              position: "absolute",
              bottom: "-3.5%",
              left: "5px",
              zIndex: "-1",
              transform: "translate(-50%, -50%) rotate(-45deg)",
              fill: "#E9E7E7",
              opacity: "0.3",
            }}
            className="midIcon"
          />
          <h2 className="text-3xl lg:text-4xl xl:text-5xl font-bold serif text-base-100 mt-24 leading-snug">
            Pomiechowskie Bezdomniaki <br />
            <strong className="text-warning">Adoptowane ❤</strong>
          </h2>
          <p className="text-lg xl:text-xl font-medium text-base-200 md:w-3/4">
            Nie wszystkie zdjęcia są zamieszczone, ale historia każdego z nich
            to dla nas i zapewne dla nich powód do ogromnej radości.
          </p>
          <div className="adoptedSwiper overflow-hidden rounded-lg self-center">
            <Swiper
              effect={"creative"}
              grabCursor={true}
              centeredSlides={true}
              slidesPerView={1}
              modules={[EffectCreative]}
              creativeEffect={{
                prev: {
                  shadow: true,
                  translate: [0, 0, -400],
                },
                next: {
                  translate: ["100%", 0, 0],
                },
              }}
              scrollbar={{ draggable: true }}
              autoplay={{ autoplay: true, delay: 3000 }}
              preventInteractionOnTransition={true}
              speed={800}
            >
              {adopted.map((el, i) => (
                <SwiperSlide key={i}>
                  <div
                    className="adoptedSwiper"
                    style={{
                      background: `linear-gradient(to top right, rgba(0, 0, 0, 0.2), transparent), url(${el.data.img})`,
                      backgroundPositionY: `40%`,
                      backgroundSize: "cover",
                      position: "relative",
                      zIndex: "10",
                    }}
                  ></div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      )}
    </>
  );
}

export default Adopted;
