import React, { useState, useEffect } from "react";
import NewsElement from "../components/NewsElement";
import hero from "../assets/newshero.jpg";
import dog from "../assets/dog.svg";
import Loading from "../components/Loading";
import bg from "../assets/news-bg.svg";

import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase.config";

function News() {
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchNews = async () => {
      const docRef = collection(db, "news");
      const docSnap = await getDocs(docRef);
      const newsArr = [];

      docSnap.forEach((doc) => {
        return newsArr.push({
          data: doc.data(),
          timestamp: new Date(doc.data().date.seconds * 1000),
        });
      });

      setNews(
        newsArr.sort((a, b) => b.data.date.seconds - a.data.date.seconds)
      );
      setLoading(false);
    };
    fetchNews();
  }, []);

  return (
    <main>
      <section
        style={{
          backgroundImage: `linear-gradient(rgba(233, 231, 231, 0.8), rgba(233, 231, 231, 0.8)), url(${bg})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
        className="mb-24"
      >
        <div className="flex flex-col-reverse lg:flex-row justify-center gap-16 bg-primary py-24 mb-24 relative z-20 px-4">
          <div
            style={{
              position: "absolute",
              transform: "rotate(20deg)",
              zIndex: "-1",
            }}
            className="dogIconDiv"
          >
            <img className="dogIcon2" src={dog} alt="Ikona psa" />
          </div>
          <div className="max-w-lg border-2 border-base-200 rounded-lg overflow-hidden shadow-md self-center md:self-start">
            <img src={hero} alt="Zdjęcie psa" />
          </div>
          <h1 className="text-2xl leading-snug text-left sm:text-3xl sm:leading-snug md:text-4xl md:leading-snug xl:text-5xl font-bold serif text-base-100 xl:leading-snug smtext-center lg:text-left">
            Wydarzenia i akcje <br /> Stowarzyszenia <br />
            <span className="text-warning">Pomiechowskich Bezdomniaków</span>
          </h1>
        </div>
        <section>
          {loading ? (
            <Loading />
          ) : (
            <div className="container mx-auto flex flex-col gap-24 py-24">
              {news.map((element, i) => (
                <NewsElement
                  key={i}
                  title={element.data.title}
                  text={element.data.text}
                  date={`${element.timestamp.getDate()}.${
                    element.timestamp.getMonth() + 1
                  }.${element.timestamp.getFullYear()}`}
                />
              ))}
            </div>
          )}
        </section>
      </section>
    </main>
  );
}

export default News;
