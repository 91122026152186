import React, { useState, useEffect } from "react";
import { getAuth } from "firebase/auth";
import {
  collection,
  getDocs,
  addDoc,
  deleteDoc,
  doc,
} from "firebase/firestore";
import { db } from "../firebase.config";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { v4 as uuidv4 } from "uuid";
import { ToastContainer, toast } from "react-toastify";

import Loading from "./Loading";

function AdminCats() {
  const [catForm, setCatForm] = useState({
    name: "",
    text: "",
    img: "",
  });
  const [cats, setCats] = useState([]);
  const [loading, setLoading] = useState(false);

  const auth = getAuth();

  const onMutate = (e) => {
    if (e.target.files) {
      setCatForm((prevState) => ({
        ...prevState,
        img: e.target.files[0],
      }));
    }

    if (!e.target.files) {
      setCatForm((prevState) => ({
        ...prevState,
        [e.target.id]: e.target.value,
      }));
    }
  };

  useEffect(() => {
    const fetchCats = async () => {
      setLoading(true);
      const docRef = collection(db, "adoptionCats");
      const docSnap = await getDocs(docRef);
      const catsArr = [];

      docSnap.forEach((doc) => {
        return catsArr.push({
          data: doc.data(),
          id: doc.id,
        });
      });

      setCats(catsArr);
      setLoading(false);
    };
    fetchCats();
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    // Store image in firebase
    const storeImage = async (image) => {
      return new Promise((resolve, reject) => {
        const storage = getStorage();
        const fileName = `${auth.currentUser.uid}-${uuidv4()}`;

        const storageRef = ref(storage, "images/koty/" + fileName);

        const uploadTask = uploadBytesResumable(storageRef, image);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log("Upload is " + progress + "% done");
            switch (snapshot.state) {
              case "paused":
                console.log("Upload is paused");
                break;
              case "running":
                console.log("Upload is running");
                break;
              default:
                break;
            }
          },
          (error) => {
            reject(error);
          },
          () => {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              resolve(downloadURL);
            });
          }
        );
      });
    };

    const imgUrl = await storeImage(catForm.img);

    const catFormCopy = {
      ...catForm,
    };
    delete catFormCopy.img;

    catFormCopy.img = imgUrl;

    await addDoc(collection(db, "adoptionCats"), catFormCopy);
    setLoading(false);
    setCatForm({
      name: "",
      text: "",
      img: "",
    });
    toast.success("Dodano");
  };

  const onDelete = async (listingId) => {
    if (window.confirm("Czy na pewno chcesz usunąć?")) {
      await deleteDoc(doc(db, "adoptionCats", listingId));
      const updatedCats = cats.filter((listing) => listing.id !== listingId);
      setCatForm(updatedCats);
      toast.success("Usunięto z bazy danych");
    }
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <div className="flex flex-col mb-12">
            <div className="text-4xl font-extrabold py-12">
              Dodaj kota do adopcji
            </div>
            <form onSubmit={onSubmit} className="flex flex-col gap-8">
              <input
                id="name"
                onChange={onMutate}
                required
                type="text"
                className="input input-bordered input-primary w-full max-w-xs"
                placeholder="Imię kota"
              />
              <textarea
                id="text"
                onChange={onMutate}
                className="textarea textarea-primary"
                placeholder="Krótki opis kota"
              ></textarea>
              <div className="form-control">
                <input
                  id="img"
                  onChange={onMutate}
                  type="file"
                  accept=".jpg,.png,.jpeg"
                  required
                  name="fileInput"
                  className="file-input file-input-bordered file-input-primary w-full max-w-xs"
                />
                <label htmlFor="fileInput" className="label">
                  <span className="label-text">Max rozmiar - 2MB</span>
                </label>
              </div>
              <button type="submit" className="btn btn-primary mt-8">
                Dodaj
              </button>
            </form>
          </div>
          <div className="flex flex-col gap-8 items-start">
            <div className="text-4xl font-extrabold pt-12 pb-8">
              Koty w bazie danych
            </div>
            <div className="text-xl font-medium text-neutral">
              W tym miejscu można usunąć koty do adopcji z bazy danych.
            </div>
            <div className="grid grid-rows-2 grid-cols-3 gap-6 place-items-stretch">
              {cats.map((cat) => (
                <div
                  key={cat.id}
                  id={cat.id}
                  className="dogsGridItem overflow-hidden border-2 border-base-200 rounded-lg cursor-pointer transition-all hover:opacity-90 flex items-end"
                  style={{ backgroundImage: `url(${cat.data.img})` }}
                >
                  <button
                    onClick={() => onDelete(cat.id)}
                    className="btn btn-error btn-block btn-sm"
                  >
                    {cat.data.name} - Usuń
                  </button>
                </div>
              ))}
            </div>
          </div>
          <ToastContainer />
        </>
      )}
    </>
  );
}

export default AdminCats;
