import React from "react";
import { useNavigate } from "react-router-dom";

function Navigation() {
  const navigate = useNavigate();

  return (
    <nav className="hidden xl:self-start xl:block">
      <ul className="uppercase text-md font-medium tracking-tight text-base-100 flex gap-2">
        <li>
          <button
            onClick={() => navigate("/")}
            className="btn btn-ghost whitespace-nowrap"
          >
            Strona Główna
          </button>
        </li>
        <li>
          <button
            onClick={() => navigate("/wydarzenia")}
            className="btn btn-ghost"
          >
            Wydarzenia
          </button>
        </li>
        <li>
          <button
            onClick={() => navigate("/stop")}
            className="btn btn-ghost whitespace-nowrap"
          >
            <span className="text-error ">STOP</span>  Bezdomności
          </button>
        </li>
        <li>
          <button
            onClick={() => navigate("/podopieczni")}
            className="btn btn-ghost whitespace-nowrap"
          >
            Nasi Podopieczni
          </button>
        </li>
        <li>
          <button
            onClick={() => navigate("/informacje")}
            className="btn btn-ghost"
          >
            Informacje
          </button>
        </li>
        <li>
          <button
            onClick={() => navigate("/pomoc")}
            className="btn btn-warning btn-outline"
          >
            Jak Nam Pomóc
          </button>
        </li>
      </ul>
    </nav>
  );
}

export default Navigation;
