import React, { useEffect, useState } from "react";
import Loading from "./Loading";
import Modal from "../components/Modal";
import { FaPaw } from "react-icons/fa";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Scrollbar, A11y } from "swiper";
import "swiper/css";
import "swiper/css/bundle";

import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase.config";

SwiperCore.use([Autoplay, Scrollbar, A11y]);

function AdoptCat() {
  const [cats, setCats] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalDetails, setModalDetails] = useState({
    modalClick: false,
    modalSrc: "",
    modalTitle: "",
    modalText: null,
  });

  useEffect(() => {
    const fetchCats = async () => {
      const docRef = collection(db, "adoptionCats");
      const docSnap = await getDocs(docRef);
      const catsArr = [];

      docSnap.forEach((doc) => {
        return catsArr.push({
          data: doc.data(),
        });
      });

      setCats(catsArr);
      setLoading(false);
    };
    fetchCats();
  }, []);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <div id="koty" className="flex flex-col gap-24">
            <h2 className="text-4xl xl:text-5xl font-extrabold text-base-100 mt-24">
              Koty do adopcji
            </h2>
            <div className="flex justify-between relative z-20 xl:flex-row flex-col-reverse items-center xl:items-start gap-24 xl:gap-0">
              <FaPaw
                style={{
                  position: "absolute",
                  bottom: "50%",
                  left: "30%",
                  zIndex: "-1",
                  fill: "#E9E7E7",
                  opacity: "0.3",
                  transform: "rotate(100deg)",
                }}
                className="bigIcon"
              />
              <FaPaw
                style={{
                  position: "absolute",
                  bottom: "-20%",
                  left: "-10%",
                  zIndex: "-1",
                  fill: "#E9E7E7",
                  opacity: "0.3",
                  transform: "rotate(-140deg)",
                }}
                className="bigIcon"
              />
              <div className="psySwiper border-2 border-base-200 rounded-lg overflow-hidden">
                <Swiper
                  slidesPerView={1}
                  scrollbar={{ draggable: true }}
                  autoplay={{ autoplay: true, delay: 4000 }}
                  parallax={{ parallax: true }}
                  preventInteractionOnTransition={true}
                  speed={800}
                >
                  {cats.map((cat, i) => (
                    <SwiperSlide key={i}>
                      <div
                        className="psySwiper"
                        style={{
                          background: `linear-gradient(to top right, rgba(0, 0, 0, 0.2), transparent), url(${cat.data.img})`,
                          backgroundPosition: `center`,
                          backgroundSize: "cover",
                          position: "relative",
                          zIndex: "10",
                        }}
                      >
                        <div className="text-3xl font-extrabold text-base-100 select-none z-20 absolute bottom-10 left-10">
                          {cat.data.name}
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
              <div className="grid grid-rows-1 grid-cols-3 xl:grid-cols-2 xl:grid-rows-3 gap-4 place-items-stretch">
                {cats.map((cat) => (
                  <div
                    key={cat.data.img}
                    onClick={() => {
                      setModalDetails({
                        modalClick: true,
                        modalSrc: cat.data.img,
                        modalTitle: cat.data.name,
                        modalText: cat.data.text,
                      });
                    }}
                    className="dogsGridItem overflow-hidden border-2 border-base-200 rounded-lg cursor-pointer transition-all hover:opacity-80"
                    style={{ backgroundImage: `url(${cat.data.img})` }}
                  ></div>
                ))}
              </div>
            </div>
            {modalDetails.modalClick ? (
              <Modal
                img={modalDetails.modalSrc}
                title={modalDetails.modalTitle}
                text={modalDetails.modalText}
                onClose={() => {
                  setModalDetails({
                    modalClick: false,
                    modalSrc: "",
                    modalTitle: "",
                    modalText: null,
                  });
                }}
              />
            ) : null}
          </div>
        </>
      )}
    </>
  );
}

export default AdoptCat;
