import React from "react";

function Footer() {
  return (
    <footer
      className="flex text-xs lg:text-sm justify-center py-4 text-neutral-content text-center relative z-40"
      style={{ backgroundColor: "rgba(92, 127, 103, 0.9)" }}
    >
      <p className="leading-tight">
        © Stowarzyszenie Pomiechowskie Bezdomniaki 2023 - Wszelkie prawa
        zastrzeżone <br /> Stworzone przez{" "}
        <a
          href="https://bartjozef.com"
          rel="noreferrer"
          target="_blank"
          className="link link-warning"
        >
          Bartosz Józefowicz
        </a>
        .
      </p>
    </footer>
  );
}

export default Footer;
