import React from "react";
import divider from "../../assets/divider.jpg";
import { FaPaw, FaCat, FaDog } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

function SectionIntroTwo() {
  const navigate = useNavigate();

  return (
    <section className="bg-primary py-24 relative z-10">
      <FaPaw
        style={{
          position: "absolute",
          top: "1%",
          right: "12%",
          zIndex: "-1",
          fill: "#E9E7E7",
          opacity: "0.4",
          transform: "rotate(-20deg)",
        }}
        className="bigIcon"
      />
      <div className="container mx-auto">
        <div className="flex flex-col gap-24">
          <div className="flex xl:flex-row flex-col items-center justify-center">
            <div className="max-w-xl md:max-w-2xl overflow-hidden rounded-lg border-2 border-base-100">
              <img src={divider} alt="Zdjęcie psa i człowieka" />
            </div>
            <div className="flex flex-col self-start pt-8 px-12 xl:w-1/2 gap-8">
              <h2 className="text-xl md:text-2xl lg:text-3xl xl:text-4xl font-semibold text-base-100">
                Angażujemy się w różnych formach w naszej społeczności lokalnej!
              </h2>
              <p className="text-md md:text-lg xl:text-xl font-normal text-base-200 leading-relaxed">
                Podnosimy świadomość opieki i odpowiedzialności nad zwierzętami,
                zachęcamy do czipowania, kastracji, adopcji i angażowania się w
                pomoc i wolontariat.
                <br />
                <br />
                <span className="font-extrabold">
                  Nie zajmujemy się odławianiem zwierząt z Gminy Pomiechówek
                </span>
                . Tym zajmuje się podmiot, z którym zgodnie z podejmowaną
                Uchwałą Rady Gminy, Gmina Pomiechówek ma zawartą osobną umowę.
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-col mt-20 relative gap-20">
          <FaCat
            style={{
              position: "absolute",
              top: "-10%",
              left: "-3%",
              height: "50px",
              width: "50px",
              fill: "#E9E7E7",
              opacity: "0.5",
              transform: "rotate(5deg)",
            }}
            className="introTwoCatIcon"
          />
          <FaDog
            style={{
              position: "absolute",
              top: "-10%",
              right: "0",
              height: "50px",
              width: "50px",
              fill: "#E9E7E7",
              opacity: "0.5",
              transform: "rotateY(180deg) rotate(5deg)",
            }}
            onClick={() => navigate("/admin")}
            className="introTwoDogIcon"
          />
          <h3 className="text-xl md:text-2xl lg:text-3xl xl:text-4xl font-semibold self-center text-base-100">
            Co zatem zrobić widząc błąkającego psa lub kota?
          </h3>
          <p className="text-md md:text-lg xl:text-xl font-normal text-base-200 leading-relaxed sm:w-3/4">
            Proponujemy zrobienie zdjęcia i ogłoszenie. Służymy pomocą w
            ogłaszaniu. Nasza strona na Facebooku osiąga lokalnie niemałe
            zasięgi i często udaje się namierzyć właściciela.
            <br />
            <br />
            Jeśli chcesz pomóc - postaraj się zabezpieczyć zwierzę. My nie
            działamy jak "pogotowie dla zwierząt".
            <br />
            Tak, jak my możemy pomóc, Ty też możesz!{" "}
            <strong>Razem możemy więcej</strong>! W miarę możliwości spróbujemy
            przejąć psa lub kota, ale prosimy o wyrozumiałość:
            <br />
            <br />
              1. Każdy z nas pracuje zawodowo - nie działamy "na zawołanie".
            <br />
              2. Gmina Pomiechówek ma tylko 5 kojców gminnych i są to bardzo
            ograniczone możliwości.
          </p>
          <p className="text-sm md:text-md lg:text-xl xl:text-2xl font-semibold text-base-100 self-center">
            Do obserwowania naszych bieżących działań zapraszamy na stronę na{" "}
            <a
              href="https://www.facebook.com/pomiechowskie.bezdomniaki/"
              rel="noreferrer"
              target="_blank"
              className="link link-warning"
            >
              Facebooku
            </a>
          </p>
        </div>
      </div>
    </section>
  );
}

export default SectionIntroTwo;
