import React from "react";

function Modal({ img, onClose, text, title }) {
  return (
    <div
      onClick={onClose}
      className="flex items-center justify-center h-screen w-screen z-40"
      style={{
        position: "fixed",
        top: "0",
        left: "0",
        backgroundColor: "rgba(0, 0, 0, 0.7)",
      }}
    >
      <div
        className="modalImg relative z-50 rounded-lg overflow-hidden shadow-xl"
        style={{
          backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.2), transparent), url(${img})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <button
          onClick={onClose}
          className="btn btn-circle btn-outline btn-neutral btn-sm absolute top-4 right-4"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
        <div className="text-2xl sm:text-3xl font-extrabold text-base-100 absolute top-4 left-4 drop-shadow">
          {title}
        </div>
        {text && (
          <div
            className="text-xs sm:text-md font-medium text-base-100 absolute bottom-0 left-0 p-4 w-full"
            style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
          >
            {text}
          </div>
        )}
      </div>
    </div>
  );
}

export default Modal;
