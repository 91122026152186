import React, { useState } from "react";
import AdminDogs from "../components/AdminDogs";
import AdminCats from "../components/AdminCats";
import AdminNews from "../components/AdminNews";
import AdminDiv from "../components/AdminDiv";

function Administration() {
  const [option, setOption] = useState("Wybierz z listy");

  const handleSelect = (e) => {
    setOption(e.target.value);
  };

  const optionRender = () => {
    if (option === "Wybierz z listy") return <AdminDiv />;
    if (option === "dogs") return <AdminDogs />;
    if (option === "cats") return <AdminCats />;
    if (option === "news") return <AdminNews />;
  };

  return (
    <div className="my-12">
      <div className="text-4xl font-extrabold mb-12 pt-12 sm:pt-0">
        Zarządzanie
      </div>
      <select
        defaultValue={option}
        onChange={handleSelect}
        className="select select-primary w-full max-w-xs mb-24"
      >
        <option disabled>Wybierz z listy</option>
        <option value="dogs">Dodaj/Usuń psy do adopcji</option>
        <option value="cats">Dodaj/Usuń koty do adopcji</option>
        <option value="news">Dodaj Wydarzenie</option>
      </select>
      {optionRender()}
    </div>
  );
}

export default Administration;
