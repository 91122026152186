import React from "react";

function ReportElement({ src }) {
  return (
    <div className="flex items-center justify-center gap-8 flex-col sm:flex-row">
      {src.map((sr, i) => (
        <div
          key={i}
          className="reportDiv border-2 border-primary rounded-lg overflow-hidden"
        >
          <img className="block w-full" src={sr} alt="Sprawozdanie" />
        </div>
      ))}
    </div>
  );
}

export default ReportElement;
