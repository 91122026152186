import React, { useState } from "react";
import ReportElement from "./ReportElement";
import spr2017 from "../assets/sprawozdanie2017.jpg";
import spr2018p1 from "../assets/sprawozdanie2018p1.jpeg";
import spr2018p2 from "../assets/sprawozdanie2018p2.jpeg";
import spr2019p1 from "../assets/sprawozdanie2019.jpg";
import spr2019p2 from "../assets/sprawozdanie2019p2.jpg";
import spr2020 from "../assets/sprawozdanie2020.jpg";
import spr2021 from "../assets/sprawozdanie2021.jpg";

function Reports() {
  const [option, setOption] = useState("sprawozdania");

  const handleSelect = (e) => {
    setOption(e.target.value);
  };

  const optionRender = () => {
    if (option === "sprawozdania") return null;
    if (option === "2017") return <ReportElement src={[spr2017]} />;
    if (option === "2018")
      return <ReportElement src={[spr2018p1, spr2018p2]} />;
    if (option === "2019")
      return <ReportElement src={[spr2019p1, spr2019p2]} />;
    if (option === "2020") return <ReportElement src={[spr2020]} />;
    if (option === "2021") return <ReportElement src={[spr2021]} />;
  };

  return (
    <section className="mb-24">
      <div className="container mx-auto">
        <div className="flex flex-col gap-12">
          <h2 className="text-2xl md:text-3xl lg:text-4xl font-extrabold">
            Sprawozdania
          </h2>
          <select
            defaultValue={option}
            onChange={handleSelect}
            className="select select-primary w-full max-w-xs mb-12"
          >
            <option value="sprawozdania" disabled>
              Sprawozdania &darr;
            </option>
            <option value="2017">Sprawozdanie za rok 2017</option>
            <option value="2018">Sprawozdanie za rok 2018</option>
            <option value="2019">Sprawozdanie za rok 2019</option>
            <option value="2020">Sprawozdanie za rok 2020</option>
            <option value="2021">Sprawozdanie za rok 2021</option>
            <option value="2022">Sprawozdanie za rok 2022</option>
          </select>
          {optionRender()}
        </div>
      </div>
    </section>
  );
}

export default Reports;
