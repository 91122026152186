import Header from "./layout/Header";
import Home from "./layout/Home";
import News from "./pages/News";
import Homeless from "./pages/Homeless";
import Pets from "./pages/Pets";
import Contact from "./pages/Contact";
import Help from "./pages/Help";
import Admin from "./pages/Admin";
import Footer from "./layout/Footer";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

function App() {
  return (
    <>
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/wydarzenia" element={<News />}></Route>
          <Route path="/stop" element={<Homeless />}></Route>
          <Route path="/podopieczni" element={<Pets />}></Route>
          <Route path="/informacje" element={<Contact />}></Route>
          <Route path="/pomoc" element={<Help />}></Route>
          <Route path="/admin" element={<Admin />}></Route>
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
