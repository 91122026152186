import React from "react";
import { useNavigate } from "react-router-dom";

function MobileNav({ className, closeMenu }) {
  const navigate = useNavigate();

  return (
    <nav className={`${className} transition-all block xl:hidden`}>
      <ul className="uppercase text-lg font-medium tracking-tight text-base-100 flex flex-col items-center gap-4">
        <li>
          <button
            onClick={() => {
              navigate("/");
              setTimeout(() => closeMenu(), 200);
            }}
            className="btn btn-ghost whitespace-nowrap btn-md sm:btn-lg"
          >
            Strona Główna
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              navigate("/wydarzenia");
              setTimeout(() => closeMenu(), 200);
            }}
            className="btn btn-ghost btn-md sm:btn-lg"
          >
            Wydarzenia
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              navigate("/stop");
              setTimeout(() => closeMenu(), 200);
            }}
            className="btn btn-ghost whitespace-nowrap btn-md sm:btn-lg"
          >
            <span className="text-error ">STOP</span>  Bezdomności
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              navigate("/podopieczni");
              setTimeout(() => closeMenu(), 200);
            }}
            className="btn btn-ghost whitespace-nowrap btn-md sm:btn-lg"
          >
            Nasi Podopieczni
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              navigate("/informacje");
              setTimeout(() => closeMenu(), 200);
            }}
            className="btn btn-ghost btn-block sm:btn-lg"
          >
            Informacje
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              navigate("/pomoc");
              setTimeout(() => closeMenu(), 200);
            }}
            className="btn btn-warning text-warning btn-ghost sm:btn-lg"
          >
            Jak Nam Pomóc
          </button>
        </li>
      </ul>
    </nav>
  );
}

export default MobileNav;
