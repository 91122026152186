import React from "react";
import logo from "../../assets/bezdomniaki-zielony.svg";
import dog from "../../assets/contact-dog.svg";
import { GiPawHeart } from "react-icons/gi";
import { GoMail } from "react-icons/go";

function ContactHero() {
  return (
    <section className="mt-12 py-12 px-8 mb-24">
      <div className="container mx-auto">
        <div className="flex flex-col-reverse xl:flex-row gap-24 xl:gap-0 items-center justify-around">
          <div className="max-w-xs">
            <img src={logo} alt="Logo Stowarzyszenia" />
          </div>
          <div className="flex flex-col items-center border-2 border-base-200 rounded-lg p-12 font-semibold bg-base-100 gap-8 relative z-20 shadow-md">
            <GiPawHeart
              style={{
                position: "absolute",
                bottom: "2%",
                right: "2%",
                height: "85px",
                width: "85px",
                opacity: "0.7",
                zIndex: "-1",
              }}
              className="text-error"
            />
            <h1 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-black leading-snug cursor-default italic text-center">
              Stowarzyszenie <br />
              <span className="text-error not-italic truncate">
                Pomiechowskie Bezdomniaki
              </span>
            </h1>
            <div className="flex flex-col text-sm sm:text-md md:text-lg lg:text-xl text-accent-content gap-1">
              <p>KRS: 0000657844</p>
              <p>NIP: 5311695946</p>
              <p>REGON: 366305701</p>
            </div>
            <p className="text-xs sm:text-md md:text-lg lg:text-xl text-accent-content">
              Cele statutowe utrzymujemy w znacznej mierze z ofiarności
              publicznej.
              <br /> Z góry dziękujemy za wsparcie i zaufanie.
            </p>
            <p className="text-sm sm:text-md md:text-lg lg:text-xl">
              Bank Pekao SA{" "}
              <span className="text-error font-extrabold">
                18 1240 3480 1111 0010 7213 5490
              </span>
            </p>
          </div>
        </div>

        <div className="flex items-center justify-around border-2 border-primary rounded-lg mt-36 p-8 font-semibold bg-base-100 relative z-20 flex-col-reverse gap-12 lg:flex-row lg:gap-0">
          <div
            className="max-w-xs contactDog"
            style={{
              position: "absolute",
              top: "-2%",
              left: "2%",
              zIndex: "-1",
              transform: "translate(-50%, -50%)",
            }}
          >
            <img src={dog} alt="Ikona Psa" />
          </div>
          <div className="flex flex-col text-md sm:text-lg md:text-xl lg:text-2xl gap-2">
            <div className="flex items-center justify-center mb-4 gap-2">
              <p>Adres wyłącznie do korespondencji</p>
              <GoMail className="contactIcon text-primary" />
            </div>
            <p className="text-sm sm:text-md md:text-lg lg:text-xl font-normal text-neutral leading-relaxed">
              Stowarzyszenie "Pomiechowskie Bezdomniaki"
              <br />
              ul. Wiśniowa 11
              <br />
              05-180 Pomiechówek
            </p>
            <p className="text-sm sm:text-md md:text-lg lg:text-xl font-normal text-neutral leading-relaxed">
              E-mail:{" "}
              <a
                href="email:pomiechowskie.bezdomniaki@gmail.com"
                className="link link-primary"
              >
                pomiechowskie.bezdomniaki@gmail.com
              </a>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ContactHero;
