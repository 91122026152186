import React, { useState } from "react";
import Logo from "../components/Logo";
import Navigation from "../components/Navigation";
import MobileNav from "../components/MobileNav";
import { FaBone } from "react-icons/fa";

function Header() {
  const [navOpen, setNavOpen] = useState(false);

  const handleClick = () => {
    setNavOpen((prevState) => !prevState);
  };

  return (
    <header className="py-8 px-24 bg-primary">
      <div className="flex items-center justify-between xl:flex-row flex-col-reverse xl:gap-0 sm:gap-8">
        <Logo />
        <Navigation />
        <button
          onClick={handleClick}
          className="btn btn-warning btn-block btn-base-100 btn-md xl:hidden shadow-md flex items-center justify-center gap-2"
        >
          <FaBone
            style={{
              height: "30px",
              width: "30px",
            }}
            className="text-base-100"
          />
          <div className="text-base-100 font-extrabold tracking-widest">
            MENU
          </div>
          <FaBone
            style={{
              height: "30px",
              width: "30px",
            }}
            className="text-base-100"
          />
        </button>
        <MobileNav
          closeMenu={handleClick}
          className={
            navOpen
              ? "menuHeight opacity-100 visible"
              : "h-0 opacity-0 invisible"
          }
        />
      </div>
    </header>
  );
}

export default Header;
