import React, { useEffect, useState } from "react";
import Loading from "./Loading";
import Modal from "./Modal";
import { FaPaw } from "react-icons/fa";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Scrollbar, A11y } from "swiper";
import "swiper/css";
import "swiper/css/bundle";

import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase.config";

SwiperCore.use([Autoplay, Scrollbar, A11y]);

function AdoptDog() {
  const [dogs, setDogs] = useState([]);
  const [loading, setLoading] = useState(true);

  const [modalDetails, setModalDetails] = useState({
    modalClick: false,
    modalSrc: "",
    modalText: null,
    modalTitle: "",
  });

  useEffect(() => {
    const fetchDogs = async () => {
      const docRef = collection(db, "adoptionDogs");
      const docSnap = await getDocs(docRef);
      const dogsArr = [];

      docSnap.forEach((doc) => {
        return dogsArr.push({
          data: doc.data(),
        });
      });

      setDogs(dogsArr);
      setLoading(false);
    };
    fetchDogs();
  }, []);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <article className="flex flex-col gap-4 relative z-20">
            <FaPaw
              style={{
                position: "absolute",
                bottom: "25%",
                right: "5%",
                zIndex: "-1",
                fill: "#E9E7E7",
                opacity: "0.3",
                transform: "rotate(40deg)",
              }}
              className="bigIcon"
            />
            <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl text-base-100 font-extrabold mb-12">
              Pomiechowskie Bezdomniaki do adopcji
            </h2>
            <p className="text-sm sm:text-md md:text-lg xl:text-xl font-medium text-base-100 leading-relaxed md:w-3/4">
              Najpierw szukamy właściciela, ale gdy przez dwa tygodnie nie uda
              się go odnaleźć szukamy dla psa nowego domu.
              <br /> Pieski będące pod naszą opieką są przede wszystkim
              socjalizowane. Codziennie uczą się przebywać z ludźmi, są
              wyprowadzane na spacery, bardzo często w towarzystwie dzieci.
              <br />
              Są zaszczepione, odrobaczone, zabezpieczone przed kleszczami i
              odpchlone. Staramy się poznawać ich charaktery i wprowadzać dobre
              nawyki.
              <br />
              <br />
              Jeżeli rozważycie Państwo przyjęcie do siebie jednego z naszych
              podopiecznych Bezdomniaków koniecznie powinniście przyjechać, lub
              przyjść i poznać go osobiście. Doświadczony wolontariusz opowie
              Wam o pieskach, oraz przeprowadzi krótki wywiad przedadopcyjny.
              <br />
              <br />
              <strong className="text-warning">
                Poprosimy także Państwa o możliwość odwiedzin w miejscu, gdzie
                planowalibyście, aby Wasz piesek zamieszkał.
              </strong>
              <br />
              <br />
              Jeśli dojdziemy do porozumienia zostanie spisana umowa adopcyjna z
              Urzędem Gminy Pomiechówek, a Wasz pupil zostanie zaczipowany.
              Warunkiem adopcji jest poddanie pieska sterylizacji/katracji,
              który to zabieg może być wykonany bezpłatnie na koszt Gminy, na
              podstawie podpisanej umowy w lecznicy wskazanej przez Urząd.{" "}
              <br /> Sterylizacja ma na celu zabezpieczenie przed potencjalnym
              rozmnażaniem się zwierząt i szerzeniu wśród nich bezdomności.
            </p>
          </article>
          <div id="psy" className="flex flex-col gap-24">
            <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-extrabold text-base-100 mt-24">
              Psy do adopcji
            </h2>
            <div className="flex justify-between relative z-20 xl:flex-row flex-col-reverse items-center xl:items-start gap-24 xl:gap-0">
              <FaPaw
                style={{
                  position: "absolute",
                  bottom: "50%",
                  right: "30%",
                  zIndex: "-1",
                  fill: "#E9E7E7",
                  opacity: "0.4",
                  transform: "rotate(100deg)",
                }}
                className="bigIcon"
              />
              <FaPaw
                style={{
                  position: "absolute",
                  bottom: "-20%",
                  left: "-10%",
                  zIndex: "-1",
                  fill: "#E9E7E7",
                  opacity: "0.4",
                  transform: "rotate(-140deg)",
                }}
                className="bigIcon"
              />
              <div className="psySwiper border-2 border-base-200 rounded-lg overflow-hidden">
                <Swiper
                  slidesPerView={1}
                  scrollbar={{ draggable: true }}
                  autoplay={{ autoplay: true, delay: 4000 }}
                  parallax={{ parallax: true }}
                  preventInteractionOnTransition={true}
                  speed={800}
                >
                  {dogs.map((dog, i) => (
                    <SwiperSlide key={i}>
                      <div
                        className="psySwiper"
                        style={{
                          background: `linear-gradient(to top right, rgba(0, 0, 0, 0.2), transparent), url(${dog.data.img})`,
                          backgroundPosition: `center`,
                          backgroundSize: "cover",
                          position: "relative",
                          zIndex: "10",
                        }}
                      >
                        <div className="text-3xl font-extrabold text-base-100 select-none z-20 absolute bottom-10 left-10">
                          {dog.data.name}
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
              <div className="grid grid-rows-2 grid-cols-3 xl:grid-cols-2 xl:grid-rows-3 gap-4 place-items-stretch">
                {dogs.map((dog) => (
                  <div
                    key={dog.data.img}
                    onClick={() => {
                      setModalDetails({
                        modalSrc: dog.data.img,
                        modalClick: true,
                        modalText: dog.data.text,
                        modalTitle: dog.data.name,
                      });
                    }}
                    className="dogsGridItem overflow-hidden border-2 border-base-200 rounded-lg cursor-pointer transition-all hover:opacity-80"
                    style={{ backgroundImage: `url(${dog.data.img})` }}
                  ></div>
                ))}
              </div>
            </div>
            {modalDetails.modalClick ? (
              <Modal
                img={modalDetails.modalSrc}
                text={modalDetails.modalText}
                title={modalDetails.modalTitle}
                onClose={() => {
                  setModalDetails({
                    modalSrc: "",
                    modalText: null,
                    modalTitle: "",
                    modalClick: false,
                  });
                }}
              />
            ) : null}
          </div>
        </>
      )}
    </>
  );
}

export default AdoptDog;
