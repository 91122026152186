import React from "react";
import hero1 from "../assets/hero1.jpg";
import hero2 from "../assets/hero2.jpg";
import hero3 from "../assets/hero3.jpg";

function HeroImg() {
  return (
    <div className="flex items-center justify-center gap-1 sm:gap-4 hero-col">
      <div
        className="overflow-hidden rounded-lg border-2 border-base-100 heroimg1"
        style={{ backgroundImage: `url(${hero1})` }}
      ></div>
      <div
        className="overflow-hidden rounded-lg border-2 border-base-100 heroimg2"
        style={{ backgroundImage: `url(${hero2})` }}
      ></div>
      <div
        className="overflow-hidden rounded-lg border-2 border-base-100 heroimg3"
        style={{ backgroundImage: `url(${hero3})` }}
      ></div>
    </div>
  );
}

export default HeroImg;
