import React from "react";
import { FaPaw } from "react-icons/fa";

function Paws({ className }) {
  return (
    <div
      className={`p-8 relative inline-block ${className}`}
      style={{
        height: "100px",
        width: "100px",
      }}
    >
      <FaPaw
        style={{
          height: "40px",
          width: "40px",
          fill: "#F87272",
        }}
      />
      <FaPaw
        style={{
          height: "34px",
          width: "34px",
          position: "absolute",
          top: "-10%",
          left: "-10%",
          fill: "#F87272",
        }}
      />
      <FaPaw
        style={{
          height: "28px",
          width: "28px",
          position: "absolute",
          top: "-25%",
          right: "5%",
          fill: "#F87272",
        }}
      />
      <FaPaw
        style={{
          height: "28px",
          width: "28px",
          position: "absolute",
          top: "-55%",
          left: "5%",
          fill: "#F87272",
        }}
      />
    </div>
  );
}

export default Paws;
