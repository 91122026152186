import React, { useState } from "react";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../firebase.config";
import { ToastContainer, toast } from "react-toastify";

import Loading from "./Loading";

function AdminNews() {
  const [newsForm, setNewsForm] = useState({
    title: "",
    text: "",
  });
  const [dateForm, setDateForm] = useState({
    date: 0,
  });
  const [loading, setLoading] = useState(false);

  const onMutate = (e) => {
    if (e.target.id !== "date") {
      setNewsForm((prevState) => ({
        ...prevState,
        [e.target.id]: e.target.value,
      }));
    }
  };

  const onDateChange = (e) => {
    setDateForm(() => ({
      date: new Date(e.target.value),
    }));
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    await addDoc(collection(db, "news"), {
      ...newsForm,
      ...dateForm,
    });
    setLoading(false);
    setNewsForm({
      title: "",
      text: "",
      date: "",
    });
    toast.success("Dodano wydarzenie.");
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <div className="flex flex-col mb-12">
            <div className="text-4xl font-extrabold py-12">
              Dodaj akcję/wydarzenie
            </div>
            <form onSubmit={onSubmit} className="flex flex-col gap-8">
              <input
                id="title"
                onChange={onMutate}
                required
                type="text"
                className="input input-bordered input-primary w-full max-w-xs"
                placeholder="Tytuł"
              />
              <textarea
                id="text"
                onChange={onMutate}
                className="textarea textarea-primary"
                placeholder="Opis wydarzenia"
                required
              ></textarea>
              <div className="form-control">
                <input
                  id="date"
                  onChange={onDateChange}
                  type="date"
                  required
                  name="dateInput"
                  className="input input-bordered input-primary w-full max-w-xs"
                />
                <label htmlFor="dateInput" className="label">
                  <span className="label-text">Wybierz datę wydarzenia</span>
                </label>
              </div>
              <button type="submit" className="btn btn-primary mt-8">
                Dodaj
              </button>
            </form>
          </div>

          <ToastContainer />
        </>
      )}
    </>
  );
}

export default AdminNews;
