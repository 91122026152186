import React from "react";
import HeroImg from "./HeroImg";

function Hero() {
  return (
    <section className="bg-primary pt-12 pb-24 px-12">
      <div className="container mx-auto">
        <div className="flex flex-col items-center xl:flex-row justify-center gap-3 sm:gap-12 singleHero">
          <HeroImg />
          <div className="flex flex-col align-center justify-center self-start">
            <h1 className="text-2xl sm:text-3xl md:text-4xl leading-tight lg:text-5xl lg:leading-tight 2xl:text-6xl serif font-bold text-base-100 2xl:leading-tight cursor-default">
              <span className="dsap">Stowarzyszenie </span>
              <span className="text-warning font-bold">
                Pomiechowskie Bezdomniaki
              </span>
            </h1>
            <a
              href="#intro"
              className="btn btn-outline btn-secondary hover:btn-warning sm:rounded-full self-start mt-8 px-8 btn-sm sm:px-12 sm:btn-md"
            >
              Poznaj nas &darr;
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Hero;
