import React from "react";
import { GiPawHeart } from "react-icons/gi";

function CTA() {
  return (
    <section className="py-20 relative">
      <GiPawHeart
        style={{
          position: "absolute",
          fill: "#F87272",
          opacity: "0.6",
        }}
        className="ctaIcon"
      />
      <div className="container mx-auto">
        <div className="flex flex-col items-center gap-20">
          <h2 className="text-xl md:text-2xl lg:text-3xl xl:text-4xl font-bold leading-snug">
            Zapraszamy na nasze aukcje charytatywne na Facebooku
          </h2>
          <div className="flex flex-col xl:gap-0 gap-8 xl:flex-row justify-between">
            <h3 className="text-lg md:text-xl lg:text-2xl xl:text-3xl font-bold leading-snug w-1/2">
              Bazarek Stowarzyszenia{" "}
              <span className="text-primary font-black">
                Pomiechowskie Bezdomniaki
              </span>
            </h3>
            <div className="flex flex-col gap-1">
              <p className="text-lg md:text-xl lg:text-2xl font-bold leading-snug">
                Płatności:
              </p>
              <p className="text-md md:text-lg lg:text-xl font-semibold leading-snug text-neutral">
                Przelewy na konto stowarzyszenia:
              </p>
              <p className="text-md md:text-lg lg:text-xl font-bold leading-snug text-error">
                18 1240 3480 1111 0010 7213 5490
              </p>
              <p className="text-md md:text-lg lg:text-xl font-semibold leading-snug text-neutral">
                Z dopiskiem "Cele statutowe"
              </p>
            </div>
          </div>
          <p className="text-lg md:text-xl lg:text-2xl xl:text-3xl font-bold leading-snug text-center">
            Opłacone fanty możemy wysłać pocztą <br />
            <span className="text-md md:text-lg lg:text-xl xl:text-2xl font-semibold">
              Doliczamy wówczas koszt wysyłki
            </span>
          </p>
          <p className="text-md md:text-lg lg:text-xl xl:text-2xl font-bold leading-snug text-center text-primary">
            Prosimy o finalizację w terminie do 7 dni
          </p>
        </div>
      </div>
    </section>
  );
}

export default CTA;
