// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDDP8YQ8BB-iJ4M_LbxKSO0P3y8wCZEykk",
  authDomain: "pomiechowskie-bezdomniaki.firebaseapp.com",
  projectId: "pomiechowskie-bezdomniaki",
  storageBucket: "pomiechowskie-bezdomniaki.appspot.com",
  messagingSenderId: "683412960181",
  appId: "1:683412960181:web:841fa9f7c66695c9c1dffc",
};

// Initialize Firebase
initializeApp(firebaseConfig);
export const db = getFirestore();
