import React from "react";
import volunteers from "../../assets/volunteers.jpg";
import { FaPaw } from "react-icons/fa";

import { TiTick } from "react-icons/ti";

function SectionIntro() {
  return (
    <section id="intro" className="py-48 relative">
      <FaPaw
        style={{
          position: "absolute",
          bottom: "15%",
          right: "10%",
          zIndex: "-1",
          fill: "#FBBD23",
          opacity: "0.4",
          transform: "rotate(40deg)",
        }}
        className="bigIcon"
      />
      <FaPaw
        style={{
          position: "absolute",
          top: "10%",
          right: "25%",
          zIndex: "-1",
          fill: "#5C7F67",
          opacity: "0.4",
          transform: "rotate(-30deg)",
        }}
        className="bigIcon"
      />
      <div className="container mx-auto">
        <div className="flex flex-col gap-24">
          <h2 className="text-3xl md:text-4xl lg:text-5xl font-normal serif italic xl:mb-24">
            Kilka słów o nas...
          </h2>
          <div className="flex xl:flex-row gap-12 xl:gap-0 flex-col justify-around">
            <h3 className="text-xl md:text-2xl xl:text-3xl 2xl:text-4xl font-medium tracking-tight sm:w-3/4 xl:w-1/2 leading-snug pt-8">
              Jesteśmy grupą <span className="text-primary">wolontariuszy</span>
              , która charytatwynie pomaga w opiece nad bezdomnymi zwierzętami z
              gminy Pomiechówek.
            </h3>
            <div className="max-w-full rounded-lg border-2 border-primary overflow-hidden">
              <img src={volunteers} alt="Wolontariusze" />
            </div>
          </div>
          <p className="text-md md:text-lg leading-relaxed lg:text-xl lg:leading-relaxed font-normal text-neutral sm:w-3/4 ">
            Łączy nas jedno - <strong>miłość do zwierząt</strong> i chęć pomocy
            tym, którzy o tą pomoc nie poproszą sami. <br />
            Schroniska teoretycznie są koniecznością, ale dla każdego psa i kota
            wiążą się z traumatycznym przeżyciem.
            <br />
            Bywa, że spędzają tam wiele lat, a niekiedy całe swoje życie.
            Staramy się to zmienić by ich przykre przeżycia trwały możliwie jak
            najkrócej.
            <br />
            <br />
            <br />
            Nasza przygoda z wolontariatem rozpoczęła się w 2015 roku. Wówczas
            jako grupa amatorów-zapaleńców w porozumieniu z lokalnym samorządem
            podejmowaliśmy się opieki nad zwierzętami zagubionymi i porzuconymi
            w <strong>Gminie Pomiechówek</strong>. Od tej pory do schronisk
            pojechało dosłownie kilka psów, którymi w momentach największego
            obłożenia kojców nie byliśmy w stanie się zająć.
          </p>
          <div className="flex flex-col gap-3 text-md md:text-lg lg:text-xl font-normal text-neutral">
            <div className="flex items-center gap-2">
              <TiTick className="tick text-success" />
              <p>
                Od 2017 roku zarejestrowaliśmy nasze Stowarzyszenie <br />{" "}
                uzyskując wpis do KRS: 0000657844
              </p>
            </div>
            <div className="flex items-center gap-2">
              <TiTick className="tick text-success" />
              <p>Od 31.12.2019 roku posiadamy również status OPP.</p>
            </div>
          </div>
          <div className="flex flex-col gap-12 font-normal text-neutral mt-16">
            <p className="text-xl md:text-2xl leading-snug lg:text-3xl lg:leading-snug font-semibold text-primary sm:w-3/4 ">
              Od 2018 roku corocznie startujemy w konkursie ogłaszanym przez
              Gminę Pomiechówek na sprawowanie opieki nad kojcami gminnymi
              przejmując to zadanie!
            </p>
            <p className="text-md md:text-lg leading-relaxed lg:text-xl lg:leading-relaxed font-normal sm:w-3/4 self-start ">
              Na ten cel otrzymujemy dotację, która powinna zabezpieczyć
              utrzymanie obiektu, wyżywienie zwierząt i kwotę na pracownika
              zatrudnianego na umowę - zlecenie, który wypełnia obowiązki opieki
              nad zwierzętami w tygodniu. W weekendy i dni świąteczne pracujemy
              sami zachęcając do współpracy wolontariuszy już od 13 roku życia.
              Jako organizacja zabezpieczamy wszelkie potrzeby, na które brakuje
              środków, a także specjalistyczne leczenie i inne działania, by
              efekty opieki nad naszymi zwierzętami były na maksymalnym
              poziomie, jaki tylko możemy im zapewnić.
              <br />
              <br />
              Ponadto, zgodnie z naszymi założeniami statutowymi wspieramy
              kastracje i czasem leczenie zwierząt właścicielskich w sytuacjach,
              gdy opiekun nie jest w stanie udźwignąć tego ciężaru sam.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SectionIntro;
