import React, { useState, useEffect } from "react";
import Administration from "../components/Administration";
import { BsChevronRight } from "react-icons/bs";
import { AiFillLock } from "react-icons/ai";
import {
  getAuth,
  signInWithEmailAndPassword,
  onAuthStateChanged,
} from "firebase/auth";
import { ToastContainer, toast } from "react-toastify";
import Loading from "../components/Loading";
import "react-toastify/dist/ReactToastify.css";

function Admin() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loggedIn, setLoggedIn] = useState(false);
  const [loading, setLoading] = useState(false);

  const auth = getAuth();

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  const handlePassword = (e) => {
    setPassword(e.target.value);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);

      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );

      if (userCredential.user) setLoggedIn(true);
      setLoading(false);

      toast.success("Witaj Kasia lub Patrycja! :)");
      setEmail("");
      setPassword("");
    } catch (err) {
      setLoading(false);
      toast.error("Nie udało się zalogować!");
    }
  };

  const onLogout = () => {
    try {
      const auth = getAuth();

      auth.signOut();
      setLoggedIn(false);
      toast.success("Wylogowano.");
    } catch {
      toast.error("Coś poszło nie tak!");
    }
  };

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) setLoggedIn(true);
    });
  }, [auth]);

  return (
    <div className="container mx-auto mb-12 relative z-20">
      {loading ? <Loading /> : null}
      {loggedIn ? (
        <button
          onClick={onLogout}
          className="btn btn-error absolute top-0 right-0 flex items-center justify-center gap-2 mr-4 btn-sm sm:btn-md"
        >
          Wyloguj się
          <AiFillLock className="h-5 w-5" />
        </button>
      ) : null}
      {!loggedIn ? (
        <div className="flex flex-col signInScreen">
          <div className="text-3xl md:text-4xl font-extrabold py-12">
            Zaloguj się, aby zarządzać
          </div>
          <form className="flex items-center gap-4 flex-col sm:flex-row">
            <input
              onChange={handleEmail}
              type="email"
              placeholder="Email"
              className="input input-bordered input-primary w-full max-w-xs"
              value={email}
            />
            <input
              onChange={handlePassword}
              type="password"
              placeholder="Hasło"
              className="input input-bordered input-primary w-full max-w-xs"
              value={password}
            />
            <button
              type="submit"
              onClick={handleLogin}
              className="btn btn-circle btn-primary"
            >
              <BsChevronRight className="loginIcon" />
            </button>
          </form>
        </div>
      ) : (
        <Administration />
      )}
      <ToastContainer autoClose={3000} />
    </div>
  );
}

export default Admin;
